import React, { FC } from 'react';
import { StyledBackDrop, StyledCircularProgress } from './FullPageLoadingSpinner.styled';

type FullPageLoadingSpinnerProps = {
  isLoading?: boolean;
};

const FullPageLoadingSpinner: FC<FullPageLoadingSpinnerProps> = ({ isLoading = true, ...circularProgressProps }) => {
  return (
    <StyledBackDrop open={isLoading}>
      <StyledCircularProgress color="primary" {...circularProgressProps} />
    </StyledBackDrop>
  );
};

export { FullPageLoadingSpinner };
