import { fetchCurrentUser } from '@api/user';
import { AppBar } from '@components/AppBar';
import { FullPageLoadingSpinner } from '@components/FullPageLoadingSpinner';
import { ErrorMessageSnackbar } from '@components/Snackbar';
import { auth } from '@core/firebase';
import { useErrorMessage } from '@hooks/useErrorMessage';
import useMakeErrorMessageFromResponse from '@hooks/useMakeErrorMessageFromResponse';
import { useUser } from '@hooks/useUser';
import useViewportHeight from '@hooks/useViewportHeight';
import React, { FC, Suspense, useEffect, useState } from 'react';
import { renderRoutes, RouteConfigComponentProps } from 'react-router-config';
import { Wrapper } from './AuthenticatedAppContainer.styled';

const AuthenticatedAppContainer: FC<RouteConfigComponentProps> = ({ route, history }) => {
  const viewportHeight = useViewportHeight();

  const [isPendingForUserAuthState, setIsPendingForUserAuthState] = useState(true);

  const { setUser } = useUser();
  const { errorMessage, setErrorMessage } = useErrorMessage();
  const { makeErrorMessage } = useMakeErrorMessageFromResponse();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const currentUserData = await fetchCurrentUser();

          setUser(currentUserData);
        } catch (error) {
          setErrorMessage(makeErrorMessage(error));
        } finally {
          setIsPendingForUserAuthState(false);
        }
      } else {
        history.push('/login');
      }
    });

    return unsubscribe;
  }, [history, setUser, setErrorMessage, makeErrorMessage]);

  if (isPendingForUserAuthState) {
    return <FullPageLoadingSpinner />;
  }

  return (
    <Wrapper viewportHeight={viewportHeight}>
      <AppBar />

      <Suspense fallback={<FullPageLoadingSpinner />}>{renderRoutes(route?.routes)}</Suspense>

      <ErrorMessageSnackbar
        message={errorMessage}
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </Wrapper>
  );
};

export { AuthenticatedAppContainer };
