import { axiosInstance } from '@core/axiosInstance';
import { Language } from '@core/models';
import { AxiosResponse } from 'axios';

export type FetchLanguagesParams = {
  localeCode: string;
};

export type AssignUserLanguagesPayload = {
  firstLanguage?: string;
  secondLanguage?: string;
  isFirstLanguage?: boolean;
  menuLanguage?: string;
};

export type AssignUserLanguagesParams = {
  payload: AssignUserLanguagesPayload;
};

export const fetchLanguages = ({ localeCode }: FetchLanguagesParams) =>
  axiosInstance.get('/User/Languages', { params: { localeCode } }).then(({ data }: AxiosResponse<Language[]>) => data);

export const assignUserLanguages = ({ payload }: AssignUserLanguagesParams) =>
  axiosInstance.post('/User/AssignLanguages', payload);
