import { SharedWith } from './common';

export type SandboxProject = {
  id: string;
  title: string;
  createdDate: string;
  imageUrl?: string;
  hasSeen?: boolean;
  sharedWith?: SharedWith[];
};

export enum NodeSize {
  Big = 0,
  Small = 1,
}

export type MyProject = {
  id: string;
  title: string;
  imageUrl?: string;
  hasSeen?: boolean;
  createdDate: Date;
};

export type TeacherTemplate = {
  id: string;
  title: string;
  imageUrl?: string;
  hasSeen?: boolean;
  createdDate: Date;
  sharedWithIds?: string[];
};

export type GanTemplate = {
  id: string;
  title: string;
  imageUrl?: string;
  hasSeen?: boolean;
  createdDate: Date;
};

export type SandboxTask = {
  myProjects: MyProject[];
  teacherTemplates: TeacherTemplate[];
  ganTemplates: GanTemplate[];
};

export type SandboxTemplate = {
  id: string;
  color: string;
  label: string;
};

export type FormPayloadSandboxType = {
  isEdit: boolean;
  payload: FormSandboxType;
};

export type FormSandboxType = {
  id: string;
  title: string;
  localeCode?: string;
  sharedWithIds: string[];
};

export type CardDetail = {
  id: string;
  title: string;
  imageUrl: string;
  audioUrl: string;
  signLanguageUrl: string;
  widgetSymbolUrl: string;
  isCategory: boolean;
  isOverride: boolean;
  isOwned: boolean;
  categoryId: string;
  backgroundColor: string;
  shareWith: SharedWith[];
};

export type NodeSandbox = {
  id: string;
  positionX: number;
  positionY: number;
  isSmallSize: boolean;
  cardDetail: CardDetail;
};

export type SandboxDetail = {
  id: string;
  title: string;
  isOwned: boolean;
  totalAreas: number;
  areasLabel: string[];
  nodes: NodeSandbox[];
};
