import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import {
  cocogooseLight,
  cocogooseRegular,
  ralewayBold,
  ralewayRegular,
  sfProDisplayBold,
  sfProDisplayRegular,
} from './fonts';

export interface ICustomTheme {
  custom: {
    appBar: {
      height: React.CSSProperties['height'];
      svgExpansionLength: {
        [size in Breakpoint]: React.CSSProperties['height'] | React.CSSProperties['padding'];
      };
    };
    backgroundColor: {
      main: React.CSSProperties['backgroundColor'];
      dark: React.CSSProperties['backgroundColor'];
    };
  };
}

const textPrimaryColor = '#373737';
const textSecondaryColor = '#919191';
const textHintColor = '#b2b2b2';

export const customBreakpointsTheme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },
} as ThemeOptions);

export const theme = createMuiTheme({
  typography: {
    fontFamily: 'SFProDisplay, Raleway, Cocogoose, Roboto',
  },
  palette: {
    primary: {
      main: '#6751e7',
      dark: '#4838a1',
      light: '#c0bae4',
      contrastText: '#fff',
    },
    secondary: {
      light: '#e4e0ff',
      main: '#d2ccff',
    },
    text: {
      primary: textPrimaryColor,
      secondary: textSecondaryColor,
      hint: textHintColor,
    },
    success: {
      main: '#59d57b',
    },
    error: {
      main: '#d43132',
      dark: '#c52122',
      light: '#f9baba',
      contrastText: '#fff',
    },
  },
  custom: {
    appBar: {
      height: '7.6875rem',
      svgExpansionLength: {
        xs: '0px',
        sm: '0px',
        md: '0.5rem',
        lg: '1rem',
        xl: '1.5rem',
      },
    },
    backgroundColor: {
      main: '#e8e5eb',
      dark: '#dedae2',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          sfProDisplayRegular,
          sfProDisplayBold,
          ralewayRegular,
          ralewayBold,
          cocogooseLight,
          cocogooseRegular,
        ],
        html: {
          [customBreakpointsTheme.breakpoints.up('xs')]: {
            'font-size': 8,
          },
          [customBreakpointsTheme.breakpoints.up('sm')]: {
            'font-size': 10,
          },
          [customBreakpointsTheme.breakpoints.up('md')]: {
            'font-size': 11.38,
          },
          [customBreakpointsTheme.breakpoints.up('lg')]: {
            'font-size': 12.5,
          },
          [customBreakpointsTheme.breakpoints.up('xl')]: {
            'font-size': 16,
          },
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
    MuiInputLabel: {
      root: {
        '&$error': {
          color: textPrimaryColor,
          fontSize: '1rem',
          fontWeight: 'bold',
          letterSpacing: 0,
        },
        '&$shrink': {
          transform: 'translate(0px, 0px) scale(1)',
          fontSize: '1rem',
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: '1rem',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: textPrimaryColor,

        '&$focused': {
          color: textPrimaryColor,
        },
      },
    },
    MuiInputBase: {
      input: {
        color: textPrimaryColor,
        fontSize: '1.125rem',
        letterSpacing: 0,

        '&::placeholder': {
          color: textHintColor,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:after, &:before, &:hover:not(.Mui-disabled):before, &.Mui-error:after': {
          borderBottomColor: '#d8d8d8',
        },
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '1rem',
        fontWeight: 'bold',
        letterSpacing: 0,
        color: '#d43131',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 30,
      },
    },
    MuiDialogContent: {
      dividers: {
        borderTop: 'none',
        borderBottom: 'none',
        boxShadow: `
          inset 0px 11px 8px -10px #cccccc52,
          inset 0px -11px 8px -10px #cccccc52`,
      },
    },
    MuiDialogActions: {
      root: {
        justifyContent: 'center',
      },
      spacing: {
        '& > :not(:first-child)': {
          marginLeft: '1.875rem',
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: '0',
      },
    },
  },
});

export type CustomizedTheme = typeof theme;
