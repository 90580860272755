import React, { FC, ChangeEvent } from 'react';
import { SwitchContainer, SwitchButton } from './ToggleSwitch.styled';

type Side = 'left' | 'right';

type ToggleSwitchOptions = {
  [side in Side]: {
    value: string;
    label: string;
  };
};

export type ToggleSwitchProps = {
  value?: string;
  color: 'primary' | 'secondary';
  width: number | string;
  options: ToggleSwitchOptions;
  onChange: (newValue: string) => void;
};

const ToggleSwitch: FC<ToggleSwitchProps> = ({ value = '', options, color, width, onChange }) => {
  const handleChange = (e: ChangeEvent<{}>, newValue: string) => {
    onChange(newValue);
  };

  return (
    <SwitchContainer value={value} onChange={handleChange} color={color} width={width}>
      {Object.keys(options).map((side) => (
        <SwitchButton
          key={side}
          value={options[side as Side].value}
          label={options[side as Side].label}
          selected={value === options[side as Side].value}
          color={color}
          disableRipple
        />
      ))}
    </SwitchContainer>
  );
};

export { ToggleSwitch };
