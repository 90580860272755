import { TextFieldProps } from '@material-ui/core';
import { useField, FieldHookConfig } from 'formik';
import React, { FC } from 'react';
import { StyledMuiTextField } from './TextField.styled';

const TextField: FC<TextFieldProps & FieldHookConfig<string>> = (props) => {
  const [field, meta] = useField(props);

  return (
    <StyledMuiTextField
      data-testid="form.text-field"
      id={props.name}
      inputProps={{ 'data-testid': 'form.text-field.input', ...props.inputProps }}
      fullWidth
      {...field}
      error={meta.touched && !!meta.error}
      helperText={meta.touched && meta.error}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
};

export { TextField };
