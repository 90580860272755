import React, { FC, createContext, useState } from 'react';

type NavigationRouteContextProps = {
  backToPath: string;
  setBackToPath: (path: string) => void;
};

export const NavigationRouteContext = createContext<NavigationRouteContextProps>({
  backToPath: '/',
  setBackToPath: () => undefined,
});

export const NavigationRouteContextProvider: FC = ({ children }) => {
  const [backToPath, setBackToPath] = useState('/');

  return (
    <NavigationRouteContext.Provider
      value={{
        backToPath,
        setBackToPath,
      }}
    >
      {children}
    </NavigationRouteContext.Provider>
  );
};
