import Bowser from 'bowser';
import React, { FC, ImgHTMLAttributes, useState } from 'react';
import { StyledImage } from './ContentCardImage.styled';

const { name: browserName, version = '' } = Bowser.parse(window.navigator.userAgent).browser;
const browserVersion = parseInt(version.split('.')[0] || '0');
const isTouchDevice = window.navigator.maxTouchPoints > 0;
const isOnIos13OrOlder = browserName === 'Safari' && browserVersion <= 13 && isTouchDevice;

export type Side = 'height' | 'width';

type ContentCardImageProps = ImgHTMLAttributes<HTMLImageElement>;

const ContentCardImage: FC<ContentCardImageProps> = (props) => {
  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
  const [limitedSide, setLimitedSide] = useState<Side>('height');

  const handleLoad = () => {
    const originalWidth = imageElement?.naturalWidth || 1;
    const parentWidth = imageElement?.parentElement?.clientWidth || 1;
    const currentWidth = imageElement?.clientWidth || 1;
    const originalHeight = imageElement?.naturalHeight || 1;
    const parentHeight = imageElement?.parentElement?.clientHeight || 1;
    const currentHeight = imageElement?.clientHeight || 1;

    const isPortraitImage = originalWidth > originalHeight;
    const isFitInContainerVertically = currentHeight < parentHeight;
    const isOverflowHorizontally = currentWidth > parentWidth;

    if (isPortraitImage && (isFitInContainerVertically || isOverflowHorizontally)) {
      setLimitedSide('width');
    }
  };

  return (
    <StyledImage
      {...props}
      onLoad={handleLoad}
      ref={setImageElement}
      limitedSide={limitedSide}
      isOnIos13OrOlder={isOnIos13OrOlder}
    />
  );
};

export { ContentCardImage };
