import { User, UserRole } from '@core/models';
import React, { createContext, FC, useState } from 'react';

type UserContextProps = {
  user: User | null;
  activeLanguage: string | undefined;
  isTeacher: boolean;
  setUser: (user: User | null) => void;
};

export const UserContext = createContext<UserContextProps>({
  user: null,
  activeLanguage: 'en',
  isTeacher: false,
  setUser: () => undefined,
});

export const UserContextProvider: FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const activeLanguage = user?.isFirstLanguage ? user.firstLanguage : user?.secondLanguage;
  const isTeacher = user?.role === UserRole.Teacher; // TODO: refactor by using this flag instead of do a checking on a component

  return <UserContext.Provider value={{ user, activeLanguage, isTeacher, setUser }}>{children}</UserContext.Provider>;
};
