import { css } from '@emotion/core';
import { PlayArrow, Pause, Replay } from '@material-ui/icons';
import { styled } from '@styles/styled';

export const VideoPlayerWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 0.375rem;
`;

export const StyledVideo = styled.video`
  height: 100%;
  object-fit: cover;
`;

export const ControlsOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0.375rem;
  background-color: rgba(0, 0, 0, 0.2);
`;

const iconStyles = css`
  font-size: 5rem;
  fill: rgba(255, 255, 255, 0.7);
`;

export const PlayIcon = styled(PlayArrow)`
  ${iconStyles}
`;

export const PauseIcon = styled(Pause)`
  ${iconStyles}
`;

export const ReplayIcon = styled(Replay)`
  ${iconStyles}
`;
