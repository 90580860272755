import { ErrorResponseData } from '@type-def/errorResponseData';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

const useMakeErrorMessageFromResponse = () => {
  const { formatMessage } = useIntl();

  const makeErrorMessage = useCallback(
    (error: unknown) => {
      const statusCode = (error as AxiosError<ErrorResponseData | string>).response?.status;
      switch (statusCode) {
        case 404:
          return formatMessage({ id: 'error_message.not_found' });
        case 500:
          return formatMessage({ id: 'error_message.server_error' });
        default:
          return formatMessage({ id: 'error_message.error_occcured' });
      }
    },
    [formatMessage]
  );

  return { makeErrorMessage };
};

export default useMakeErrorMessageFromResponse;
