export const convertBlobToBase64 = (blob: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const isImage = (file: File) => {
  return /^image\//.test(file.type);
};

export const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const convertURL = (url: string | File) => {
  return typeof url === 'string' ? url : window.URL.createObjectURL(url);
};

export const getWidth = (width: number | string | undefined) => {
  if (!width) {
    return '';
  }

  if (typeof width === 'number' || /^\d+$/.test(width)) {
    return `${width}px`;
  }

  return width;
};
