import { styled } from '@styles/styled';

export const Wrapper = styled.div<{ viewportHeight: number }>`
  background-color: ${({ theme }) => theme.custom.backgroundColor.main};
  min-height: ${({ viewportHeight }) => viewportHeight}px;

  ${({ theme }) => {
    const { height, svgExpansionLength } = theme.custom.appBar;

    return `
      padding-top: calc(${height} + ${svgExpansionLength.sm});

    ${theme.breakpoints.up('md')} {
      padding-top: calc(${height} + ${svgExpansionLength.md});
    }

    ${theme.breakpoints.up('lg')} {
      padding-top: calc(${height} + ${svgExpansionLength.lg});
    }

    ${theme.breakpoints.up('xl')} {
      padding-top: calc(${height} + ${svgExpansionLength.xl});
    }
  `;
  }}
`;
