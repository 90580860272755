import { assignUserLanguages } from '@api/language';
import { Language } from '@core/models';
import { useErrorMessage } from '@hooks/useErrorMessage';
import mapKeyArrayToDataArray from '@utils/mapKeyArrayToDataArray';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useUser } from './useUser';

export const useUserActiveLanguageToggle = (languageOptions: Language[]) => {
  const { formatMessage } = useIntl();
  const { user, setUser } = useUser();
  const { setErrorMessage } = useErrorMessage();

  const [firstLanguageData, secondLanguageData] = mapKeyArrayToDataArray<Language>(
    'langCode',
    [user?.firstLanguage || '', user?.secondLanguage || ''],
    languageOptions
  );

  const [assignUserLanguagesMutation] = useMutation(assignUserLanguages, {
    onMutate: ({ payload }) => {
      if (!user) {
        setErrorMessage(formatMessage({ id: 'error_message.user_not_found' }));
        return;
      }

      setUser({ ...user, ...payload });

      return payload.isFirstLanguage;
    },
    onError: (error, variable, onMutateValue) => {
      setErrorMessage(formatMessage({ id: 'error_message.error_switching_language' }));

      if (!user) {
        setErrorMessage(formatMessage({ id: 'error_message.user_not_found' }));
        return;
      }

      setUser({ ...user, isFirstLanguage: !onMutateValue });
    },
  });

  const handleToggleLangauge = () => {
    if (!(user?.firstLanguage && user?.secondLanguage)) return;

    assignUserLanguagesMutation({
      payload: {
        isFirstLanguage: !(user?.isFirstLanguage ?? true),
      },
    });
  };

  return { firstLanguageData, secondLanguageData, handleToggleLangauge };
};
