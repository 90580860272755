import { css } from '@emotion/core';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { cardColorSet, ColorVariant } from '@styles/cardColorVariant';
import { styled } from '@styles/styled';

type CardProps = {
  color?: ColorVariant;
};

export const StyledDialog = styled(Dialog)`
  & div.MuiDialog-paper {
    max-width: none;
    border-radius: 1.875rem;

    @media only screen and (max-width: 1200px) {
      & {
        max-width: 65rem;
      }
    }
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: center;
  padding: 1.5rem 1.875rem 1.2rem 1.875rem;
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0 2rem;
  scrollbar-width: thin;
  scrollbar-color: #babac0c9 white;

  &::-webkit-scrollbar {
    background-color: white;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 2px solid white;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }
`;

export const CloseIconButton = styled(IconButton)`
  top: 2rem;
  right: 1.8rem;
  position: absolute;
`;

export const CloseIcon = styled(Close)`
  font-size: 2.1rem;
`;

const makeCardBaseStyle = ({ color = ColorVariant.Fallback }: CardProps) => {
  const cardColor = cardColorSet[color] || cardColorSet[ColorVariant.Fallback];

  return css`
    width: 28rem;
    height: 23rem;
    background-color: ${cardColor.background};
    border: 0.3125rem solid ${cardColor.border};
    border-radius: 1.25rem;
    box-shadow: 0px 0.1875rem 0px 0.125rem ${cardColor.border}, 0px 0.1875rem 0.125rem 0.1875rem ${cardColor.shadow};
    padding: 1.5rem 1.75rem 2.1rem 1.75rem;
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;

    &:nth-of-type(2),
    &:nth-of-type(3) {
      margin-left: 2.5rem;
    }

    @media only screen and (max-width: 1200px) {
      &:nth-of-type(3) {
        margin-left: 0;
      }
    }
  `;
};

export const Card = styled.div<CardProps>(
  (props) => css`
    ${makeCardBaseStyle(props)}
  `
);

export const ClickableCard = styled.div<CardProps>(
  (props) => css`
    ${makeCardBaseStyle(props)}

    cursor: pointer;

    &.Mui-focusVisible {
      outline: 2px auto #0000ff73;
    }
  `
);

export const CardTitle = styled(Typography)`
  font-size: 1.5625rem;
  font-weight: bold;
  letter-spacing: 0px;
  min-height: 2.3125rem;
`;

export const CardContent = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 1.3rem;
  border-radius: 0.375rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;
