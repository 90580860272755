import { AuthenticatedAppContainer } from '@core/AuthenticatedAppContainer';
import Login from '@pages/Login';
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

const Modes = lazy(() => import(/* webpackChunkName: "Modes" */ '@pages/Modes'));
const Language = lazy(() => import(/* webpackChunkName: "Language" */ '@pages/Language'));
const MindmapDashboardPage = lazy(() =>
  import(/* webpackChunkName: "MindmapDashboardPage" */ '@pages/Mindmap/MindmapDashboardPage')
);
const MindmapDetailsPage = lazy(() =>
  import(/* webpackChunkName: "MindmapDetailsPage" */ '@pages/Mindmap/MindmapDetailsPage')
);
const SandboxDashboardPage = lazy(() =>
  import(/* webpackChunkName: "Sandbox" */ '@pages/Sandbox/SandboxDashboardPage')
);

const SandboxDetailPage = lazy(() =>
  import(/* webpackChunkName: "SandboxDetailPage" */ '@pages/Sandbox/SandboxDetailPage')
);
const Admin = lazy(() => import(/* webpackChunkName: "Admin" */ '@pages/Admin'));
const Category = lazy(() => import(/* webpackChunkName: "Category" */ '@pages/Language/pages/Category'));
const Subcategory = lazy(() => import(/* webpackChunkName: "Category" */ '@pages/Language/pages/SubCategory'));

// TODO: make paths constant

export const privateRoutes = [
  {
    path: '/',
    render: () => <Redirect to="/modes" />,
    exact: true,
  },
  {
    path: '/modes',
    component: Modes,
    exact: true,
    title: 'sprak',
    backTo: '/modes',
  },
  {
    path: '/language',
    component: Language,
    exact: false,
    title: 'language',
    backTo: '/modes',
    routes: [
      {
        path: '/language/category',
        component: Category,
        exact: true,
        title: 'language',
        backTo: '/modes',
        isParentMode: false,
      },
      {
        path: '/language/:id/subcategory',
        component: Subcategory,
        exact: true,
        title: 'language',
        isParentMode: false,
      },
    ],
  },
  {
    path: '/language-parents',
    component: Language,
    exact: false,
    title: 'language_parents',
    backTo: '/modes',
    routes: [
      {
        path: '/language-parents/category',
        component: Category,
        exact: true,
        title: 'language_parents',
        backTo: '/modes',
        isParentMode: true,
      },
      {
        path: '/language-parents/:id/subcategory',
        component: Subcategory,
        exact: true,
        title: 'language_parents',
        isParentMode: true,
      },
    ],
  },
  {
    path: '/mindmap',
    component: MindmapDashboardPage,
    exact: true,
    title: 'mindmap',
    backTo: '/modes',
  },
  {
    path: '/mindmap/:id',
    component: MindmapDetailsPage,
    exact: true,
    title: 'mindmap',
    backTo: '/mindmap',
  },
  {
    path: '/sandbox',
    component: SandboxDashboardPage,
    exact: true,
    title: 'sandbox',
    backTo: '/modes',
  },
  {
    path: '/sandbox/:id',
    component: SandboxDetailPage,
    exact: true,
    title: 'sandbox',
    backTo: '/sandbox',
  },
  {
    path: '/admin',
    component: Admin,
    exact: true,
    title: 'sprak',
    backTo: '/modes',
  },
  {
    path: '*',
    render: () => <h1>Not found</h1>,
    title: 'sprak',
    backTo: '/modes',
  },
];

const rootRoutes = [
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/',
    component: AuthenticatedAppContainer,
    routes: privateRoutes,
  },
];

export default rootRoutes;
