import { ButtonRounded, ButtonRoundedProps } from '@components/buttons/ButtonRounded/ButtonRounded';
import { DialogProps } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { StyledDialog, StyledDialogTitle, StyledDialogContent, StyledDialogActions } from './ConfirmationModal.styed';

type ModalAction = ButtonRoundedProps & {
  label?: string;
  render?: () => ReactNode;
};

type ConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  dialogTitle?: ReactNode;
  dialogContent?: ReactNode;
  actions: ModalAction[];
};

const ConfirmationModal: FC<ConfirmationModalProps & DialogProps> = ({
  open,
  onClose,
  dialogTitle,
  dialogContent,
  actions = [],
  ...dialogProps
}) => {
  return (
    <StyledDialog open={open} onClose={onClose} {...dialogProps}>
      {dialogTitle && <StyledDialogTitle>{dialogTitle}</StyledDialogTitle>}

      {dialogContent && <StyledDialogContent>{dialogContent}</StyledDialogContent>}

      <StyledDialogActions>
        {actions.map(({ label, render, ...buttonProps }, index) => {
          if (render) {
            return render();
          } else {
            return (
              <ButtonRounded key={index} type="button" width="8rem" {...buttonProps}>
                {label}
              </ButtonRounded>
            );
          }
        })}
      </StyledDialogActions>
    </StyledDialog>
  );
};

export { ConfirmationModal };
