import { axiosInstance } from '@core/axiosInstance';
import { LanguageCard, LanguageCardPlacement, SharedWith } from '@core/models';
import { AxiosResponse } from 'axios';
import { serialize } from 'object-to-formdata';

export type FetchLanguageCardsParams = {
  localeCode: string;
  isParentMode: boolean;
  categoryId?: string;
  includeShared?: boolean;
};

export type FetchLanguageCardsData = {
  cards: Cards;
  parentCategoryId?: string;
};

export type FetchLanguageCardParams = {
  id: string;
  localeCode: string;
};

export type FetchLanguageCardData = LanguageCard;
export type CreateLanguageCardData = LanguageCard;
export type UpdateLanguageCardData = LanguageCard;

export type Cards = LanguageCard[];

export type CreateLanguageCardParams = {
  payload: CreateLanguageCardPayload;
};

export type CreateLanguageCardPayload = {
  title: string;
  localeCode: string;
  isCategory: boolean;
  isParentMode: boolean;
  shareWith?: string[];
  image: File | string;
  audio: File | string;
  categoryId?: string;
};

export type UpdateLanguageCardParams = {
  payload: CreateLanguageCardPayload;
};

export type UpdateLanguageCardPayload = {
  id: string;
  title: string;
  localeCode: string;
  shareWith?: string[];
  image: File | string;
  audio: File | string;
  isRemoveImage?: boolean;
  isRemoveAudio?: boolean;
};

export type DeleteLanguageCardParams = {
  id: string;
};

export type FetchLanguageCardShareWithListData = SharedWith[];

export type FetchLanguageCardPlacementListParams = {
  isParentMode: boolean;
  localeCode?: string;
};

export type FetchLanguageCardPlacementListData = LanguageCardPlacement[];

export type FetchLanguageCardImportableListParams = {
  localeCode: string;
  isParentMode: boolean;
  query?: string;
};

export type FetchLanguageCardImportableListData = LanguageCard[];

export type SearchLanguageCardParams = {
  localeCode: string;
  query?: string;
};

export type SearchLanguageCardData = LanguageCard[];

export type ChangeLanguageCardPlacementParams = {
  id: string;
  payload: {
    oldCategoryId: string;
    newCategoryId: string;
  };
};

export type ResetLanguageCardToOriginalParams = {
  id: string;
};

export type ReorderLanguageCardPositionsParams = {
  payload: {
    cardIds: string[];
    categoryId?: string;
  };
};

export type ImportLanguageCardIntoCategoryParams = {
  cardId: string;
  categoryId: string;
};

export type CloneLanguageCardIntoCategoryParams = {
  cardId: string;
  categoryId: string;
};

export type DeleteImportedLanguageCardParams = {
  cardId: string;
  categoryId: string;
};

export const fetchLanguageCards = (params: FetchLanguageCardsParams) =>
  axiosInstance.get('/LanguageCard', { params }).then(({ data }: AxiosResponse<FetchLanguageCardsData>) => data);

export const fetchLanguageCard = ({ id, localeCode }: FetchLanguageCardParams) =>
  axiosInstance
    .get(`/LanguageCard/${id}`, { params: { localeCode } })
    .then(({ data }: AxiosResponse<FetchLanguageCardData>) => data);

export const createLanguageCard = ({ payload }: CreateLanguageCardParams) => {
  const formData = serialize(payload, { indices: true });

  return axiosInstance.post('/LanguageCard', formData).then(({ data }: AxiosResponse<CreateLanguageCardData>) => data);
};

export const updateLanguageCard = ({ payload }: UpdateLanguageCardParams) => {
  const formData = serialize(payload, { indices: true });

  return axiosInstance.put('/LanguageCard', formData).then(({ data }: AxiosResponse<UpdateLanguageCardData>) => data);
};

export const deleteLanguageCard = ({ id }: DeleteLanguageCardParams) => axiosInstance.delete(`/LanguageCard/${id}`);

export const fetchLanguageCardShareWithList = () =>
  axiosInstance
    .get('/LanguageCard/ShareWithList')
    .then(({ data }: AxiosResponse<FetchLanguageCardShareWithListData>) => data);

export const fetchLanguageCardPlacementList = (params: FetchLanguageCardPlacementListParams) =>
  axiosInstance
    .get('/LanguageCard/PlacementList', { params })
    .then(({ data }: AxiosResponse<FetchLanguageCardPlacementListData>) => data);

export const fetchLanguageCardImportableList = (params: FetchLanguageCardImportableListParams) =>
  axiosInstance
    .get('/LanguageCard/Library', { params })
    .then(({ data }: AxiosResponse<FetchLanguageCardImportableListData>) => data);

export const searchLanguageCard = (params: SearchLanguageCardParams) =>
  axiosInstance.get('/LanguageCard/Search', { params }).then(({ data }: AxiosResponse<SearchLanguageCardData>) => data);

export const changeLanguageCardPlacement = ({ id, payload }: ChangeLanguageCardPlacementParams) =>
  axiosInstance.post(`/LanguageCard/${id}/Replace`, payload);

export const resetLanguageCardToOriginal = ({ id }: ResetLanguageCardToOriginalParams) =>
  axiosInstance.post(`/LanguageCard/${id}/ResetContent`);

export const reorderLanguageCardPositions = ({ payload }: ReorderLanguageCardPositionsParams) =>
  axiosInstance.post('/LanguageCard/DragAndDrop', payload);

export const importLanguageCardIntoCategory = ({ cardId, categoryId }: ImportLanguageCardIntoCategoryParams) =>
  axiosInstance.post(`/LanguageCard/${cardId}/Pointer/${categoryId}`);

export const cloneLanguageCardIntoCategory = ({ cardId, categoryId }: CloneLanguageCardIntoCategoryParams) =>
  axiosInstance.post(`/LanguageCard/${cardId}/Clone/${categoryId}`);

export const deleteImportedLanguageCard = ({ cardId, categoryId }: DeleteImportedLanguageCardParams) =>
  axiosInstance.delete(`/LanguageCard/${cardId}/Pointer/${categoryId}`);
