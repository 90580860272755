import React, { FC, createContext, useState } from 'react';

type ErrorMessageContextProps = {
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
};

export const ErrorMessageContext = createContext<ErrorMessageContextProps>({
  errorMessage: '',
  setErrorMessage: () => undefined,
});

export const ErrorMessageContextProvider: FC = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <ErrorMessageContext.Provider value={{ errorMessage, setErrorMessage }}>{children}</ErrorMessageContext.Provider>
  );
};
