import { FontsPrefetch } from '@components/FontsPrefetch';
import { FullPageLoadingSpinner } from '@components/FullPageLoadingSpinner';
import { ErrorMessageContextProvider } from '@contexts/ErrorMessageContext';
import { NavigationRouteContextProvider } from '@contexts/NavigationRouteContext';
import { UserContextProvider } from '@contexts/UserContext';
import { UserMenuLanguageContextProvider } from '@contexts/UserMenuLanguageContext';
import { ErrorBoundary } from '@core/ErrorBoundary';
import '@core/firebase';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { StylesProvider } from '@material-ui/core/styles';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { theme } from '@styles/theme';
import { ThemeProvider } from 'emotion-theming';
import { createBrowserHistory } from 'history';
import React, { FC, Suspense } from 'react';
import { QueryCache, ReactQueryCacheProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import { renderRoutes } from 'react-router-config';
import { BrowserRouter as Router } from 'react-router-dom';
import rootRoutes from 'src/routes';

export const history = createBrowserHistory();

const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.MS_APP_INSIGHTS_INSTRUMENTATION_KEY || '',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
});
appInsights.loadAppInsights();

const queryCache = new QueryCache({
  defaultConfig: { queries: { refetchOnWindowFocus: false, retry: false } },
});

const App: FC = () => {
  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <FontsPrefetch />

          <UserContextProvider>
            <UserMenuLanguageContextProvider>
              <ErrorMessageContextProvider>
                <ReactQueryCacheProvider queryCache={queryCache}>
                  <NavigationRouteContextProvider>
                    <Suspense fallback={<FullPageLoadingSpinner />}>
                      <Router>
                        <ErrorBoundary>{renderRoutes(rootRoutes)}</ErrorBoundary>
                      </Router>
                    </Suspense>
                  </NavigationRouteContextProvider>

                  <ReactQueryDevtools initialIsOpen={false} />
                </ReactQueryCacheProvider>
              </ErrorMessageContextProvider>
            </UserMenuLanguageContextProvider>
          </UserContextProvider>
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default withAITracking(reactPlugin, App);
