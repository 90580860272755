import { assignUserLanguages } from '@api/language';
import { ButtonRounded } from '@components/buttons';
import { LanguageList } from '@components/LanguageList';
import { Language } from '@core/models';
import { useErrorMessage } from '@hooks/useErrorMessage';
import { useUser } from '@hooks/useUser';
import { DialogProps } from '@material-ui/core';
import React, { FC, useState, useEffect, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import {
  StyledSelectLanguagesDialog,
  StyledSelectLanguagesDialogTitle,
  StyledSelectLanguagesDialogContent,
  StyledSelectLanguagesDialogActions,
} from './SelectLanguagesModal.styled';

type SelectLanguagesModalProps = {
  languages: Language[];
  isLoadingLanguages: boolean;
  onClose: () => void;
};

const SelectLanguagesModal: FC<SelectLanguagesModalProps & DialogProps> = ({
  open,
  onClose,
  languages,
  isLoadingLanguages,
  ...dialogProps
}) => {
  const { formatMessage } = useIntl();

  const { user, setUser } = useUser();
  const { setErrorMessage } = useErrorMessage();

  const [_firstLanguage, setFirstLanguage] = useState(user?.firstLanguage);
  const [_secondLanguage, setSecondLanguage] = useState(user?.secondLanguage);

  useEffect(() => {
    setFirstLanguage(user?.firstLanguage);
    setSecondLanguage(user?.secondLanguage);
  }, [user]);

  const hasInitialSelectedLanguages = user?.firstLanguage && user?.secondLanguage;

  const [assignUserLanguagesMutation] = useMutation(assignUserLanguages, {
    onSuccess: (data, { payload }) => {
      if (!user) {
        setErrorMessage(formatMessage({ id: 'error_message.user_not_found' }));
        return;
      }

      setUser({ ...user, ...payload });

      onClose();
    },
    onError: () => {
      setErrorMessage(formatMessage({ id: 'error_message.error_saving_languages' }));
    },
  });

  const handleClickSaveLanguages = () => {
    if (!(_firstLanguage && _secondLanguage)) return;

    assignUserLanguagesMutation({
      payload: {
        firstLanguage: _firstLanguage,
        secondLanguage: _secondLanguage,
        isFirstLanguage: user?.isFirstLanguage ?? true,
      },
    });
  };

  return (
    <StyledSelectLanguagesDialog
      open={open}
      onClose={onClose}
      scroll="body"
      maxWidth={false}
      disableBackdropClick={!hasInitialSelectedLanguages}
      disableEscapeKeyDown={!hasInitialSelectedLanguages}
      {...dialogProps}
    >
      <StyledSelectLanguagesDialogTitle disableTypography>
        <FormattedMessage id="app_bar.modal.title.select_two_languages" />
      </StyledSelectLanguagesDialogTitle>
      <StyledSelectLanguagesDialogContent dividers>
        <LanguageList
          languages={languages}
          onChange={setFirstLanguage}
          selectedLanguage={_firstLanguage}
          disallowedLanguage={_secondLanguage}
          isLoading={isLoadingLanguages}
        />
        <LanguageList
          languages={languages}
          onChange={setSecondLanguage}
          selectedLanguage={_secondLanguage}
          disallowedLanguage={_firstLanguage}
          isLoading={isLoadingLanguages}
        />
      </StyledSelectLanguagesDialogContent>
      <StyledSelectLanguagesDialogActions>
        {hasInitialSelectedLanguages && (
          <ButtonRounded onClick={onClose} width="7.5rem">
            <FormattedMessage id="global.cancel" />
          </ButtonRounded>
        )}
        <ButtonRounded
          onClick={handleClickSaveLanguages}
          colorAccent="primary"
          width="7.5rem"
          disabled={!(_firstLanguage && _secondLanguage)}
        >
          <FormattedMessage id="global.select" />
        </ButtonRounded>
      </StyledSelectLanguagesDialogActions>
    </StyledSelectLanguagesDialog>
  );
};

const MemoizedSelectLanguagesModal = memo(SelectLanguagesModal);

export { MemoizedSelectLanguagesModal };
