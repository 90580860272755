import { CustomScrollbarWrapper } from '@components/CustomScrollbarWrapper';
import { Language } from '@core/models';
import React, { FC, memo } from 'react';
import {
  StyledLanguageList,
  StyledLanguageListItem,
  StyledLanguageListItemText,
  LanguageListItemSkeleton,
} from './LanguageList.styled';

type LanguageListProps = {
  languages: Language[];
  selectedLanguage?: string;
  disallowedLanguage?: string;
  isLoading?: boolean;
  onChange: (langCode: string) => void;
};

const _LanguageList: FC<LanguageListProps> = ({
  languages,
  selectedLanguage,
  disallowedLanguage,
  isLoading,
  onChange,
}) => {
  return (
    <CustomScrollbarWrapper>
      <StyledLanguageList data-testid="language-list">
        {isLoading
          ? Array.from(Array(9).keys()).map((i) => (
              <LanguageListItemSkeleton
                data-testid="language-list-item-skeleton"
                key={i}
                variant="text"
                animation="wave"
                width={160}
              />
            ))
          : languages.map(({ title, langCode }) => {
              return (
                <StyledLanguageListItem
                  data-testid="language-list-item"
                  key={langCode}
                  button
                  onClick={() => onChange(langCode)}
                  selected={langCode === selectedLanguage}
                  autoFocus={langCode === selectedLanguage}
                  disabled={langCode === disallowedLanguage}
                >
                  <StyledLanguageListItemText>{title}</StyledLanguageListItemText>
                </StyledLanguageListItem>
              );
            })}
      </StyledLanguageList>
    </CustomScrollbarWrapper>
  );
};

const MemoizedLanguageList = memo(_LanguageList);

export { MemoizedLanguageList as LanguageList };
