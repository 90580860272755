import React, { FC } from 'react';
import { StyledLoginModeButton } from './LoginModeButton.styled';

export type LoginModeButtonProps = {
  onClick?: () => void;
  selected: boolean;
};

const LoginModeButton: FC<LoginModeButtonProps> = ({ children, ...buttonProps }) => {
  return <StyledLoginModeButton {...buttonProps}>{children}</StyledLoginModeButton>;
};

export { LoginModeButton };
