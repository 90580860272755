import { ButtonRounded } from '@components/buttons';
import { Typography } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { styled } from '@styles/styled';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

export const StyledConfirmationModal = styled(ConfirmationModal)`
  & div.MuiDialog-paper {
    width: 22.5rem;

    & > .MuiDialogTitle-root {
      padding: 2rem 2rem 0.5rem 2rem;

      & > .MuiTypography-root {
        font-size: 1.75rem;
        font-weight: bold;
        letter-spacing: -0.7px;
      }
    }

    & > .MuiDialogContent-root {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }
  }
`;

export const ImageWrapper = styled.div`
  height: 11.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LanguageCardTitle = styled(Typography)`
  color: #202527;
  font-size: 1.125rem;
  font-family: 'Raleway';
  font-weight: bold;
  margin-top: 1rem;
`;

export const DeleteButton = styled(ButtonRounded)`
  width: 8rem;
  background-color: #ffdde3;
  color: ${({ theme }) => theme.palette.error.main};

  &:hover {
    background-color: #f9ced5;
  }
`;

export const DeleteIcon = styled(DeleteOutline)`
  margin-right: 0.4rem;
`;
