import { css } from '@emotion/core';
import { Button } from '@material-ui/core';
import { styled } from '@styles/styled';
import { LoginModeButtonProps } from './LoginModeButton';

export const StyledLoginModeButton = styled(Button)<LoginModeButtonProps>`
  width: 6.25rem;
  height: 2.625rem;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: -0.4px;
  padding: 0;
  ${({ selected, theme }) => css`
    color: ${selected ? theme.palette.primary.main : ''};
    background: ${selected ? theme.palette.secondary.light : 'transparent'};

    &:hover {
      background: ${selected ? theme.palette.secondary.light : theme.palette.action.hover};
    }
  `}
`;
