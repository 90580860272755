import { SectionLoadingSpinner } from '@components/SectionLoadingSpinner';
import { IconButton } from '@material-ui/core';
import React, { FC, useRef, useState, useEffect } from 'react';
import { VideoPlayerWrapper, StyledVideo, ControlsOverlay, ReplayIcon } from './SignLanguageVideoPlayer.styled';

type SignLanguageVideoPlayerProps = {
  url: string;
  autoPlay?: boolean;
};

const SignLanguageVideoPlayer: FC<SignLanguageVideoPlayerProps> = ({ url, autoPlay = true, ...rest }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const videoElement = videoRef.current;

  const [isLoading, setIsLoading] = useState(true);
  const [isEnded, setIsEnded] = useState(false);
  const [, setIsPlaying] = useState(autoPlay);

  useEffect(() => {
    const handleVideoReady = () => {
      setIsLoading(false);
    };

    videoElement?.addEventListener('canplaythrough', handleVideoReady);

    return () => {
      videoElement?.removeEventListener('canplaythrough', handleVideoReady);
    };
  }, [videoElement]);

  useEffect(() => {
    const handleSetEnd = () => {
      setIsEnded(true);
      setIsPlaying(false);
      setIsLoading(false);
    };

    videoElement?.addEventListener('ended', handleSetEnd);

    return () => {
      videoElement?.removeEventListener('ended', handleSetEnd);
    };
  }, [videoElement]);

  const handlePlay = () => {
    if (!videoElement) return;

    videoElement.play();

    setIsPlaying(true);
  };

  const handleReplay = () => {
    setIsEnded(false);

    handlePlay();
  };

  return (
    <VideoPlayerWrapper>
      <StyledVideo
        ref={videoRef}
        src={url}
        muted
        autoPlay={autoPlay}
        {...rest}
        controls={false}
        playsInline
        webkit-playsinline
      />

      {isEnded && (
        <ControlsOverlay>
          <IconButton onClick={handleReplay}>
            <ReplayIcon />
          </IconButton>
        </ControlsOverlay>
      )}

      {isLoading && <SectionLoadingSpinner />}
    </VideoPlayerWrapper>
  );
};

export { SignLanguageVideoPlayer };
