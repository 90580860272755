import { IconButtonProps } from '@material-ui/core';
import React, { FC } from 'react';
import { StyledIconButton } from './ElevatedIconButton.styled';

export type ElevatedIconButtonColorAccent = 'default' | 'secondary' | 'danger';

type ElevatedIconButtonProps = {
  colorAccent?: ElevatedIconButtonColorAccent;
};

const ElevatedIconButton: FC<ElevatedIconButtonProps & IconButtonProps> = ({
  children,
  colorAccent = 'default',
  ...iconButtonProps
}) => {
  return (
    <StyledIconButton {...iconButtonProps} accent={colorAccent}>
      {children}
    </StyledIconButton>
  );
};

export { ElevatedIconButton };
