import { fetchLanguages } from '@api/language';
import { fetchLanguageCard } from '@api/languageCard';
import defaultCardImage from '@assets/images/default-image.png';
import { ToggleSwitch } from '@components/ToggleSwitch';
import { QUERY_KEY } from '@constants/queryKey';
import { LanguageCard } from '@core/models';
import { useAudio } from '@hooks/useAudio';
import { useErrorMessage } from '@hooks/useErrorMessage';
import useMakeErrorMessageFromResponse from '@hooks/useMakeErrorMessageFromResponse';
import { useUser } from '@hooks/useUser';
import { useUserActiveLanguageToggle } from '@hooks/useUserActiveLanguageToggle';
import { useUserMenuLanguage } from '@hooks/useUserMenuLanguage';
import { DialogProps } from '@material-ui/core';
import { ContentCardImage } from '@pages/Language/components/ContentCard/ContentCardImage';
import React, { FC, KeyboardEvent } from 'react';
import { useQuery } from 'react-query';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  CloseIconButton,
  CloseIcon,
  ClickableCard,
  Card,
  CardTitle,
  CardContent,
} from './SignLanguageModal.styled';
import { SignLanguageVideoPlayer } from './SignLanguageVideoPlayer';

const changeVideoUrl = (url: string) => {
  const id = url.split('assets/')[1];

  return `${process.env.API_ENDPOINT_BASE_URL}/LanguageCard/Video/${id}`;
};

type SignLanguageModalProps = DialogProps & {
  defaultCardData: LanguageCard | null;
  open: boolean;
  onClose: () => void;
};

const SignLanguageModal: FC<SignLanguageModalProps> = ({ defaultCardData, open, onClose }) => {
  const { user, activeLanguage } = useUser();
  const { play } = useAudio();
  const { setErrorMessage } = useErrorMessage();
  const { makeErrorMessage } = useMakeErrorMessageFromResponse();
  const { defaultMenuLanguage } = useUserMenuLanguage();

  const { data: languages = [] } = useQuery(
    [QUERY_KEY.LANGUAGES, user?.menuLanguage || defaultMenuLanguage],
    (key, localeCode: string) => fetchLanguages({ localeCode }),
    {
      onError: (error) => setErrorMessage(makeErrorMessage(error)),
    }
  );

  const { firstLanguageData, secondLanguageData, handleToggleLangauge } = useUserActiveLanguageToggle(languages);

  const { data: card } = useQuery(
    [QUERY_KEY.LANGUAGE_CARD, activeLanguage, defaultCardData?.id],
    (key, localeCode: string, id: string) => fetchLanguageCard({ id, localeCode }),
    {
      enabled: !!defaultCardData,
      placeholderData: defaultCardData,
    }
  );

  const handlePlayAudio = () => {
    if (!card?.audioUrl) return;

    play(card.audioUrl);
  };

  const handleClickCard = () => {
    handlePlayAudio();
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar') {
      handlePlayAudio();
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose}>
      <StyledDialogTitle>
        <ToggleSwitch
          color="secondary"
          width="19.75rem"
          value={user?.isFirstLanguage ?? true ? firstLanguageData?.langCode : secondLanguageData?.langCode}
          onChange={handleToggleLangauge}
          options={{
            left: { value: firstLanguageData?.langCode || '', label: firstLanguageData?.title || '' },
            right: { value: secondLanguageData?.langCode || '', label: secondLanguageData?.title || '' },
          }}
        />

        <CloseIconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </CloseIconButton>
      </StyledDialogTitle>

      <StyledDialogContent>
        <ClickableCard color={card?.backgroundColor} onClick={handleClickCard} onKeyDown={handleKeyDown} tabIndex={0}>
          <CardTitle>{card?.title}</CardTitle>
          <CardContent>
            <ContentCardImage src={card?.imageUrl || defaultCardImage} alt={card?.title} />
          </CardContent>
        </ClickableCard>

        {user?.isSignLanguage && card?.signLanguageUrl && (
          <Card color={card?.backgroundColor}>
            <CardTitle>{card?.title}</CardTitle>
            <CardContent>
              <SignLanguageVideoPlayer url={changeVideoUrl(card.signLanguageUrl)} />
            </CardContent>
          </Card>
        )}

        {user?.isWidgetSymbol && card?.widgetSymbolUrl && (
          <Card color={card?.backgroundColor}>
            <CardTitle>{card?.title}</CardTitle>
            <CardContent>
              <ContentCardImage src={card?.widgetSymbolUrl} alt={card?.title} />
            </CardContent>
          </Card>
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
};

export { SignLanguageModal };
