import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { styled } from '@styles/styled';

export const StyledSelectLanguagesDialog = styled(Dialog)``;

export const StyledSelectLanguagesDialogTitle = styled(DialogTitle)`
  height: 5rem;
  font-family: 'Raleway';
  font-size: 1.75rem;
  font-weight: bold;
  letter-spacing: -0.7px;
  text-align: center;
`;

export const StyledSelectLanguagesDialogContent = styled(DialogContent)`
  height: 28.75rem;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(2, minmax(200px, 25rem));

  & > ul:first-of-type {
    border-right: 1px solid #cccccc52;
  }
`;

export const StyledSelectLanguagesDialogActions = styled(DialogActions)`
  height: 7rem;
`;
