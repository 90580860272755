const getUniqueDeviceId = () => {
  const UNIQUE_DEVICE_ID = 'UNIQUE_DEVICE_ID';
  const id = localStorage.getItem(UNIQUE_DEVICE_ID);

  if (id) return id;

  const newId = Math.random().toString(32).substring(2, 12);
  localStorage.setItem(UNIQUE_DEVICE_ID, newId);

  return newId;
};

export const postDataWithForm = (form: HTMLFormElement | null, input: HTMLInputElement | null, promoCode: string) => {
  if (form && input) {
    const payload = {
      action: 'useVoucher',
      sessionId: '',
      params: {
        promoCode,
        userEnvironmentId: getUniqueDeviceId(),
        failedAccessUrl: '',
        codeBasedRedirectURL: 'optional',
      },
    };

    input.value = JSON.stringify(payload);
    form.submit();
  }
};
