import { axiosInstance } from '@core/axiosInstance';
import { User } from '@core/models';
import { AxiosResponse } from 'axios';

export type UserSettingPayload = {
  isDoubleSize?: boolean;
  isSignLanguage?: boolean;
  isWidgetSymbol?: boolean;
};

export type UserSettingParams = {
  payload: UserSettingPayload;
};

export const fetchCurrentUser = () => axiosInstance.get('/User').then(({ data }: AxiosResponse<User>) => data);

export const userSetting = ({ payload }: UserSettingParams) => axiosInstance.put('/User/userSetting', payload);

export const resetAllContents = () => axiosInstance.post('/User/resetContent');
