import { auth } from '@core/firebase';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useLoginWithCustomTokenFromRedirect = () => {
  const { push, location } = useHistory();

  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isInvalidCustomToken, setIsInvalidCustomToken] = useState(false);

  useEffect(() => {
    setIsInvalidCustomToken(false);

    const token = location.search.split('?customToken=')[1];

    if (token) {
      setIsLoggingIn(true);

      auth
        .signInWithCustomToken(token)
        .then(() => {
          setIsLoggingIn(false);

          push('/');
        })
        .catch(() => {
          setIsLoggingIn(false);
          setIsInvalidCustomToken(true);
        });
    }
  }, [location.search, push]);

  return { isLoggingIn, isInvalidCustomToken, setIsInvalidCustomToken };
};
