import { ButtonRounded } from '@components/buttons';
import { SnackbarProps as MuiSnackbarProps } from '@material-ui/core';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { StyleSnackbar } from './Snackbar.styled';

type SnackbarProps = {
  onClose: () => void;
};

const Snackbar: FC<SnackbarProps & MuiSnackbarProps> = ({ onClose, ...rest }) => {
  return (
    <StyleSnackbar
      action={
        <ButtonRounded onClick={onClose}>
          <FormattedMessage id="global.dismiss" />
        </ButtonRounded>
      }
      onClose={onClose}
      {...rest}
    />
  );
};

export { Snackbar };
