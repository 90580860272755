import React, { FC } from 'react';

const fonts = ['Cocogoose', 'Raleway', 'SFProDisplay'];

const FontsPrefetch: FC = () => {
  return (
    <>
      {fonts.map((fontFamily) => (
        <span key={`${fontFamily}-normal`} style={{ fontFamily, fontWeight: 'normal' }} />
      ))}
      {fonts.map((fontFamily) => (
        <span key={`${fontFamily}-bold`} style={{ fontFamily, fontWeight: 'bold' }} />
      ))}
    </>
  );
};

export { FontsPrefetch };
