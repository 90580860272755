import { OverlayScrollbarsComponent, OverlayScrollbarsComponentProps } from 'overlayscrollbars-react';
import React, { FC } from 'react';

type CustomScrollbarWrapperProps = {} & OverlayScrollbarsComponentProps;

const CustomScrollbarWrapper: FC<CustomScrollbarWrapperProps> = ({ children, ...rest }) => {
  return (
    <OverlayScrollbarsComponent options={{ scrollbars: { autoHide: 'never' } }} {...rest}>
      {children}
    </OverlayScrollbarsComponent>
  );
};

export { CustomScrollbarWrapper };
