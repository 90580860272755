import { styled } from '@styles/styled';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

export const StyledConfirmationModal = styled(ConfirmationModal)`
  & div.MuiDialog-paper {
    width: 22.5rem;
  }
`;

export const ContentWrapper = styled.div`
  & > div.MuiTextField-root {
    margin: 2.5rem auto;
  }
`;
