import { useState } from 'react';

export const usePdfGuide = (userGuideUrl: string) => {
  const [loadingPdf, setLoadingPdf] = useState(false);

  const openPdfInNewWindow = async () => {
    setLoadingPdf(true);

    const response = await fetch(userGuideUrl, { headers: { 'Content-Type': 'application/pdf' } });
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    setLoadingPdf(false);

    window.open(url);
  };

  return { openPdfInNewWindow, loadingPdf };
};
