import feideLogoUrl from '@assets/images/feide-logo.png';
import { ButtonProps } from '@material-ui/core';
import React, { FC } from 'react';
import { FeideButton, FeideLogo } from './FeideLoginButton.styled';

const FeideLoginButton: FC<ButtonProps> = (props) => {
  return (
    <FeideButton fullWidth {...props}>
      <FeideLogo src={feideLogoUrl} />
    </FeideButton>
  );
};

export { FeideLoginButton };
