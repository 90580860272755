import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

type ResetLanguageCardConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isCategory: boolean;
};

const ResetLanguageCardConfirmationModal: FC<ResetLanguageCardConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  isCategory,
}) => {
  const { formatMessage } = useIntl();

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      dialogTitle={formatMessage({
        id: isCategory ? 'language.form.modal.title.reset_category' : 'language.form.modal.title.reset_card',
      })}
      dialogContent={formatMessage({
        id: isCategory ? 'language.form.modal.content.reset_category' : 'language.form.modal.content.reset_card',
      })}
      actions={[
        {
          label: formatMessage({ id: 'global.reset' }),
          colorAccent: 'danger',
          onClick: onConfirm,
        },
        {
          label: formatMessage({ id: 'global.cancel' }),
          colorAccent: 'primary',
          onClick: onClose,
        },
      ]}
    />
  );
};

export { ResetLanguageCardConfirmationModal };
