import { CreateCustomTokenPayload, CreateCustomTokenResponseData } from '@api/authentication';
import { ButtonRounded } from '@components/buttons';
import { TextField } from '@components/TextField';
import { Formik, FormikHelpers } from 'formik';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { StyledFormikForm } from './KidLoginForm.styled';

type KidLoginFormValues = CreateCustomTokenPayload;

type KidLoginFormProps = {
  onSubmit: (payload: KidLoginFormValues) => Promise<CreateCustomTokenResponseData>;
};

const KidLoginForm: FC<KidLoginFormProps> = ({ onSubmit }) => {
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object().shape({
    userName: Yup.string().required(formatMessage({ id: 'login.form.username.validation_message' })),
    classCode: Yup.string().required(formatMessage({ id: 'login.form.class_code.validation_message' })),
  });

  return (
    <Formik
      initialValues={{
        userName: '',
        classCode: '',
      }}
      onSubmit={(values: KidLoginFormValues, { setErrors }: FormikHelpers<KidLoginFormValues>) => {
        onSubmit(values).catch((error) => {
          if (error.request.status === 401) {
            setErrors({
              classCode: formatMessage({ id: 'login.form.error_message.incorrect_username_or_class_code' }),
            });
          }
        });
      }}
      validationSchema={validationSchema}
    >
      <StyledFormikForm>
        <TextField
          name="userName"
          label={formatMessage({ id: 'login.form.username.label' })}
          placeholder={formatMessage({ id: 'login.form.username.placeholder' })}
        />
        <TextField
          name="classCode"
          label={formatMessage({ id: 'login.form.class_code.label' })}
          placeholder={formatMessage({ id: 'login.form.class_code.placeholder' })}
        />

        <ButtonRounded type="submit" colorAccent="primary" fullWidth>
          <FormattedMessage id="login.form.button.sign_in" />
        </ButtonRounded>
      </StyledFormikForm>
    </Formik>
  );
};

export { KidLoginForm };
