import { Button } from '@material-ui/core';
import { styled } from '@styles/styled';

export const FeideButton = styled(Button)`
  height: 2.875rem;
  background: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.19);
  border-radius: 30px;
`;

export const FeideLogo = styled.img`
  height: 1.375rem;
`;
