import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import React, { FC } from 'react';
import { LoadingSpinnerWrapper } from './SectionLoadingSpinner.styled';

const SectionLoadingSpinner: FC<CircularProgressProps> = (circularProgressProps) => {
  return (
    <LoadingSpinnerWrapper>
      <CircularProgress {...circularProgressProps} />
    </LoadingSpinnerWrapper>
  );
};

export { SectionLoadingSpinner };
