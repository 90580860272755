import { ButtonProps } from '@material-ui/core';
import React, { FC } from 'react';
import { StyledButtonRounded } from './ButtonRounded.styled';

export type RoundedButtonColorAccent = 'primary' | 'secondary' | 'default' | 'danger';

export type ButtonRoundedProps = ButtonProps & {
  width?: number | string;
  colorAccent?: RoundedButtonColorAccent;
};

const ButtonRounded: FC<ButtonRoundedProps> = ({ children, colorAccent = 'default', ...rest }) => {
  return (
    <StyledButtonRounded variant="contained" {...rest} accent={colorAccent}>
      {children}
    </StyledButtonRounded>
  );
};

export { ButtonRounded };
