import { css } from '@emotion/core';
import { Tabs, Tab, Theme } from '@material-ui/core';
import { styled } from '@styles/styled';
import { getWidth } from '@utils/common';
import { ToggleSwitchProps } from './ToggleSwitch';

const getTextColor = (theme: Theme, color?: string, selected?: boolean) => {
  let colorResult = 'inherit';

  if (color === 'primary' && selected) {
    colorResult = '#241483';
  } else if (color === 'primary') {
    colorResult = theme.palette.primary.contrastText;
  } else if (color === 'secondary' && selected) {
    colorResult = theme.palette.primary.main;
  } else if (color === 'secondary') {
    colorResult = theme.palette.text.primary;
  }

  return colorResult;
};

type PartialToggleSwitchProps = Pick<ToggleSwitchProps, 'color' | 'width'>;

export const SwitchContainer = styled(Tabs)<PartialToggleSwitchProps>(
  ({ color, width, theme }) => css`
    padding: 0.3125rem;
    min-width: ${getWidth(width)};
    height: 3.125rem;
    min-height: unset;
    background-color: ${color === 'primary' ? '#5e47e6' : 'white'};
    box-shadow: inset -2px 2px 4px 0px rgba(0, 0, 0, 0.21);
    border-radius: 1.625rem;

    & .MuiTabs-flexContainer {
      height: 100%;

      & > button.MuiTab-root {
        min-width: calc(${getWidth(width)} / 2);
      }
    }

    & .MuiTabs-indicator {
      height: 100%;
      display: flex;
      justify-content: center;
      border-radius: 1.625rem;
      background-color: ${color === 'primary' ? theme.palette.secondary.main : theme.palette.secondary.light};
    }

    &:focus-within {
      background-color: #00000040;
    }
  `
);

export const SwitchButton = styled(Tab)`
  height: 100%;
  min-height: unset;
  flex-grow: 1;
  opacity: 1;
  text-transform: initial;
  transition: 0.4s;
  padding: 0px;

  ${({ color, selected, theme }) => css`
    cursor: ${selected ? 'initial' : 'pointer'};
    pointer-events: ${selected ? 'none' : 'initial'};
    color: ${getTextColor(theme, color, selected)};
  `}

  & > span.MuiTab-wrapper {
    z-index: 1;
    font-size: 1.125rem;
    font-weight: bold;
    padding: 0 0.625rem;
  }
`;
