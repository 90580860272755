import defaultCardImage from '@assets/images/default-image.png';
import { ContentCardImage } from '@pages/Language/components/ContentCard/ContentCardImage';
import React, { FC, Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  StyledConfirmationModal,
  ImageWrapper,
  LanguageCardTitle,
  DeleteButton,
  DeleteIcon,
} from './DeleteLanguageCardConfirmationModal.styled';

type DeleteLanguageCardConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isCategory: boolean;
  cardImageUrl: string;
  cardTitle: string;
};

const DeleteLanguageCardConfirmationModal: FC<DeleteLanguageCardConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
  isCategory,
  cardImageUrl,
  cardTitle,
}) => {
  const { formatMessage } = useIntl();

  return (
    <StyledConfirmationModal
      open={open}
      onClose={onClose}
      dialogTitle={formatMessage({
        id: isCategory ? 'language.form.modal.title.delete_category' : 'language.form.modal.title.delete_card',
      })}
      dialogContent={
        <Fragment>
          <ImageWrapper>
            <ContentCardImage src={cardImageUrl || defaultCardImage} alt={cardTitle} />
          </ImageWrapper>

          <LanguageCardTitle>{cardTitle}</LanguageCardTitle>
        </Fragment>
      }
      actions={[
        {
          render: () => (
            <DeleteButton onClick={onConfirm} key="delete-button">
              <DeleteIcon />
              <FormattedMessage id="global.delete" />
            </DeleteButton>
          ),
        },
        {
          label: formatMessage({ id: 'global.cancel' }),
          colorAccent: 'default',
          onClick: onClose,
        },
      ]}
    />
  );
};

export { DeleteLanguageCardConfirmationModal };
