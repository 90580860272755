import { css } from '@emotion/core';
import { styled } from '@styles/styled';
import { Side } from './ContentCardImage';

export const StyledImage = styled.img<{ limitedSide: Side; isOnIos13OrOlder: boolean }>`
  border-radius: 0.375rem;
  ${({ limitedSide, isOnIos13OrOlder }) => css`
    ${limitedSide}: 100%;
    ${isOnIos13OrOlder &&
    css`
      object-fit: contain;
      width: 100%;
    `}
  `};
`;
