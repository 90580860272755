import backgroundImage from '@assets/images/login-page-background.png';
import { Card, Typography } from '@material-ui/core';
import { styled } from '@styles/styled';

export const LoginPageWrapper = styled.div<{ viewportHeight: number }>`
  height: ${({ viewportHeight }) => viewportHeight}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.custom.backgroundColor.main} url(${backgroundImage});
  background-size: contain;
`;

export const LoginCardWrapper = styled(Card)`
  text-align: center;
  width: 23rem;
  min-width: 310px;
  background: white;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  border-radius: 30px;
  padding: 1.875rem 3.25rem;
`;

export const FeideLoginText = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.hint};
  font-size: 0.875rem;
  letter-spacing: 0px;
  margin: 1.5rem 0 0.625rem 0;
`;
