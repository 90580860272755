import { logout as logoutOnServerSide } from '@api/authentication';
import { auth } from '@core/firebase';
import { useErrorMessage } from '@hooks/useErrorMessage';
import { useUser } from '@hooks/useUser';
import { CircularProgress } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryCache } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';

type LogoutConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
};

const LogoutConfirmationModal: FC<LogoutConfirmationModalProps> = ({ open, onClose }) => {
  const queryCache = useQueryCache();
  const { push } = useHistory();
  const { setUser } = useUser();
  const { setErrorMessage } = useErrorMessage();
  const { formatMessage } = useIntl();

  const [isLogingOut, setIsLogingOut] = useState(false);

  const handleLogout = async () => {
    setIsLogingOut(true);

    try {
      await logoutOnServerSide();
      await auth.signOut();

      setUser(null);

      setIsLogingOut(false);

      queryCache.clear();

      push('/login');
    } catch (error) {
      setIsLogingOut(false);

      setErrorMessage(formatMessage({ id: 'error_message.error_logging_out' }));
    }
  };

  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      dialogTitle={formatMessage({ id: 'app_bar.profile_menu.sign_out' })}
      dialogContent={
        isLogingOut ? (
          <CircularProgress />
        ) : (
          formatMessage({
            id: 'app_bar.modal.content.logout_confirmation_message',
          })
        )
      }
      actions={[
        {
          label: formatMessage({ id: 'app_bar.profile_menu.sign_out' }),
          colorAccent: 'danger',
          disabled: isLogingOut,
          onClick: handleLogout,
        },
        {
          label: formatMessage({ id: 'global.cancel' }),
          colorAccent: 'primary',
          disabled: isLogingOut,
          onClick: onClose,
        },
      ]}
    />
  );
};

export { LogoutConfirmationModal };
