export enum ColorVariant {
  Fallback = 'Fallback',
  Default = 'Default',
  DefaultShared = 'DefaultShared',
  Orange = 'Orange',
  Red = 'Red',
  Purple = 'Purple',
  Pink = 'Pink',
  Grey = 'Grey',
  Brown = 'Brown',
  Blue = 'Blue',
  SeaGreen = 'SeaGreen',
  GreenYellow = 'GreenYellow',
  Green = 'Green',
}

export type ColorData = {
  background: string;
  border: string;
  shadow: string;
};

export const cardColorSet: { [variant in ColorVariant]: ColorData } = {
  [ColorVariant.Fallback]: {
    background: 'white',
    border: '#f9f9f9',
    shadow: '#ececec',
  },
  [ColorVariant.Default]: {
    background: 'white',
    border: '#b8a1ea',
    shadow: '#8f68e4',
  },
  [ColorVariant.DefaultShared]: {
    background: 'white',
    border: '#edc204',
    shadow: '#b99806',
  },
  [ColorVariant.Orange]: {
    background: 'white',
    border: '#fc8a25',
    shadow: '#c1691b',
  },
  [ColorVariant.Red]: {
    background: 'white',
    border: '#e84242',
    shadow: '#b73232',
  },
  [ColorVariant.Purple]: {
    background: 'white',
    border: '#aa54e8',
    shadow: '#803cb1',
  },
  [ColorVariant.Pink]: {
    background: 'white',
    border: '#e78dec',
    shadow: '#bb72bf',
  },
  [ColorVariant.Grey]: {
    background: 'white',
    border: '#7c8893',
    shadow: '#5d666f',
  },
  [ColorVariant.Brown]: {
    background: 'white',
    border: '#765e4c',
    shadow: '#503e31',
  },
  [ColorVariant.Blue]: {
    background: 'white',
    border: '#3262ff',
    shadow: '#1e47ce',
  },
  [ColorVariant.SeaGreen]: {
    background: 'white',
    border: '#64bebb',
    shadow: '#4c9694',
  },
  [ColorVariant.GreenYellow]: {
    background: 'white',
    border: '#9ff47c',
    shadow: '#7bc35d',
  },
  [ColorVariant.Green]: {
    background: 'white',
    border: '#40ab44',
    shadow: '#38903b',
  },
};
