import { resetAllContents } from '@api/user';
import { SectionLoadingSpinner } from '@components/SectionLoadingSpinner';
import { useErrorMessage } from '@hooks/useErrorMessage';
import { TextField } from '@material-ui/core';
import React, { FC, useState, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryCache } from 'react-query';
import { useHistory } from 'react-router';
import { StyledConfirmationModal, ContentWrapper } from './ResetContentConfirmationModal.styled';

type ResetContentConfirmationModalProps = {
  open: boolean;
  onClose: () => void;
};

const ResetContentConfirmationModal: FC<ResetContentConfirmationModalProps> = ({ open, onClose }) => {
  const queryCache = useQueryCache();
  const { replace } = useHistory();
  const { formatMessage } = useIntl();
  const { setErrorMessage } = useErrorMessage();

  const referenceText = formatMessage({ id: 'app_bar.modal.reset_reference_text' });

  const [confirmationText, setConfirmationText] = useState('');

  const [resetAllContentsMutation, { isLoading: isResetting }] = useMutation(resetAllContents, {
    onSuccess: () => {
      onClose();
      queryCache.clear();
      replace('/modes', { resetContentSuccess: true });
    },
    onError: () => {
      setErrorMessage(formatMessage({ id: 'error_message.server_error' }));
    },
  });

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmationText(e.target.value);
  };

  return (
    <StyledConfirmationModal
      open={open}
      onClose={onClose}
      onExited={() => setConfirmationText('')}
      dialogTitle={formatMessage({ id: 'app_bar.modal.title.reset_content' })}
      dialogContent={
        <ContentWrapper>
          <FormattedMessage id="app_bar.modal.content.confirm_to_reset" values={{ referenceText }} />
          <TextField
            value={confirmationText}
            onChange={handleTextFieldChange}
            disabled={isResetting}
            placeholder={formatMessage({ id: 'app_bar.modal.text_input_placeholder.reset_content' })}
            fullWidth
          />

          {isResetting && <SectionLoadingSpinner />}
        </ContentWrapper>
      }
      actions={[
        {
          label: formatMessage({ id: 'app_bar.modal.reset' }),
          colorAccent: 'danger',
          disabled: isResetting || confirmationText !== referenceText,
          onClick: () => resetAllContentsMutation(),
        },
        {
          label: formatMessage({ id: 'global.cancel' }),
          colorAccent: 'primary',
          disabled: isResetting,
          onClick: onClose,
        },
      ]}
    />
  );
};

export { ResetContentConfirmationModal };
