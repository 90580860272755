import { Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import { styled } from '@styles/styled';

export const MenuLanguageDialog = styled(Dialog)`
  & div.MuiDialog-paper {
    width: 28.25rem;
    background-color: white;
    box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.36);
    border-radius: 30px;
  }
`;

export const MenuLanguageDialogTitle = styled(DialogTitle)`
  text-align: center;
  height: 5rem;

  & > .MuiTypography-root {
    font-size: 1.75rem;
    font-family: 'Raleway';
    font-weight: bold;
    letter-spacing: -0.7px;
  }
`;

export const MenuLanguageDialogContent = styled(DialogContent)`
  padding: 0 4.75rem;
  box-shadow: inset 0px 0.875rem 1rem -1.5rem rgba(0, 0, 0, 0.45), inset 0px -0.875rem 1rem -1.5rem rgba(0, 0, 0, 0.45);
`;

export const MenuLanguageDialogActions = styled(DialogActions)`
  height: 6.75rem;
`;

export const MenuLanguageList = styled(List)``;

export const MenuLanguageListItem = styled(ListItem)`
  margin: 0.5rem 0;
  height: 2.5rem;
  border-radius: 24px;

  &.MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    ${({ selected, theme }) =>
      selected &&
      `
    background-color: ${theme.palette.secondary.light};
    color: ${theme.palette.primary.main};
    `}

    &:focus {
      background-color: #d6d2f5;
    }
  }
`;

export const MenuLanguageListItemText = styled(ListItemText)`
  & > .MuiTypography-body1 {
    color: inherit;
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0px;
  }
`;
