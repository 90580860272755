import { useRef, useEffect } from 'react';

type UseAudioOptions = {
  stopOnUnmount?: boolean;
  clearOnUnmount?: boolean;
};

export const useAudio = ({ stopOnUnmount = true, clearOnUnmount = true }: UseAudioOptions = {}) => {
  const audioInstanceRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    audioInstanceRef.current = new Audio();

    return () => {
      if (stopOnUnmount && clearOnUnmount) {
        stop();
        audioInstanceRef.current = null;
      } else if (stopOnUnmount) {
        stop();
      }
    };
  }, [audioInstanceRef, stopOnUnmount, clearOnUnmount]);

  const play = (url: string) => {
    if (!audioInstanceRef.current) return;

    audioInstanceRef.current.src = url;
    audioInstanceRef.current.play();
  };

  const pause = () => {
    if (!audioInstanceRef.current) return;

    audioInstanceRef.current.pause();
  };

  const stop = () => {
    if (!audioInstanceRef.current) return;

    audioInstanceRef.current.pause();
    audioInstanceRef.current.currentTime = 0;
  };

  return {
    play,
    pause,
    stop,
  };
};
