import { assignUserLanguages } from '@api/language';
import { ButtonRounded } from '@components/buttons';
import { FullPageLoadingSpinner } from '@components/FullPageLoadingSpinner';
import { MENU_LANGUAGE } from '@constants/menuLanguage';
import { useErrorMessage } from '@hooks/useErrorMessage';
import { useUser } from '@hooks/useUser';
import { useUserMenuLanguage } from '@hooks/useUserMenuLanguage';
import { DialogProps } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import {
  MenuLanguageDialog,
  MenuLanguageDialogActions,
  MenuLanguageDialogContent,
  MenuLanguageDialogTitle,
  MenuLanguageList,
  MenuLanguageListItem,
  MenuLanguageListItemText,
} from './MenuLanguageModal.styled';

type MenuLanguageModalProps = DialogProps & {
  onClose: () => void;
};

const menuLanguageOptions = [
  {
    label: 'English',
    langCode: MENU_LANGUAGE.English,
  },
  {
    label: 'Norsk Bokmål',
    langCode: MENU_LANGUAGE.NorwegianBokmal,
  },
  {
    label: 'Norsk Nynorsk',
    langCode: MENU_LANGUAGE.NorwegianNynorsk,
  },
];

const MenuLanguageModal: FC<MenuLanguageModalProps> = ({ onClose, ...dialogProps }) => {
  const { user, setUser } = useUser();
  const { defaultMenuLanguage } = useUserMenuLanguage();

  const { formatMessage } = useIntl();
  const { setErrorMessage } = useErrorMessage();

  const [selectedLanguage, setSelectedLanguage] = useState(defaultMenuLanguage);

  const [assignUserMenuLanguageMutation, { isLoading: isUpdating }] = useMutation(assignUserLanguages, {
    onSuccess: (data, { payload }) => {
      if (!user) {
        setErrorMessage(formatMessage({ id: 'error_message.user_not_found' }));
        return;
      }

      setUser({ ...user, ...payload });

      onClose();
    },
    onError: () => {
      setErrorMessage(formatMessage({ id: 'error_message.error_setting_menu_language' }));
    },
  });

  const handleClickSelect = () => {
    assignUserMenuLanguageMutation({
      payload: {
        menuLanguage: selectedLanguage,
      },
    });
  };

  return (
    <MenuLanguageDialog onClose={onClose} {...dialogProps}>
      <MenuLanguageDialogTitle>
        <FormattedMessage id="app_bar.modal.title.menu_language" />
      </MenuLanguageDialogTitle>

      <MenuLanguageDialogContent>
        <MenuLanguageList>
          {menuLanguageOptions.map(({ label, langCode }) => (
            <MenuLanguageListItem
              data-testid="menu-language-list-item"
              key={langCode}
              button
              onClick={() => setSelectedLanguage(langCode)}
              selected={langCode === selectedLanguage}
            >
              <MenuLanguageListItemText>{label}</MenuLanguageListItemText>
            </MenuLanguageListItem>
          ))}
        </MenuLanguageList>

        <FullPageLoadingSpinner isLoading={isUpdating} />
      </MenuLanguageDialogContent>

      <MenuLanguageDialogActions>
        <ButtonRounded width="7.5rem" disabled={isUpdating} onClick={onClose}>
          <FormattedMessage id="global.cancel" />
        </ButtonRounded>
        <ButtonRounded
          width="7.5rem"
          colorAccent="primary"
          disabled={isUpdating || selectedLanguage === defaultMenuLanguage}
          onClick={handleClickSelect}
        >
          <FormattedMessage id="global.select" />
        </ButtonRounded>
      </MenuLanguageDialogActions>
    </MenuLanguageDialog>
  );
};

export { MenuLanguageModal };
