import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { styled } from '@styles/styled';

const sidePadding = '2.6875rem';

export const StyledDialog = styled(Dialog)`
  & div.MuiDialog-paper {
    width: 20rem;
    min-height: 17.375rem;
    box-shadow: 0px 2px 26px 0px rgba(0, 0, 0, 0.36);
    border-radius: 30px;
    text-align: center;
  }
`;

export const StyledDialogTitle = styled(DialogTitle)`
  padding: 2.9375rem ${sidePadding} 0.5rem ${sidePadding};

  & > .MuiTypography-root {
    font-size: 1.75rem;
    font-family: 'Raleway';
    letter-spacing: -0.7px;
  }
`;

export const StyledDialogContent = styled(DialogContent)`
  font-size: 1.25rem;
  font-family: 'Raleway';
  letter-spacing: -0.5px;
  padding: 0.5rem ${sidePadding};
`;

export const StyledDialogActions = styled(DialogActions)`
  padding: 0.5rem ${sidePadding} 1.625rem ${sidePadding};
`;
