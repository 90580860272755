import { fetchAppConfigs } from '@api/appConfigs';
import { fetchLanguages } from '@api/language';
import { userSetting } from '@api/user';
import { FullPageLoadingSpinner } from '@components/FullPageLoadingSpinner';
import {
  LogoutConfirmationModal,
  MenuLanguageModal,
  ResetContentConfirmationModal,
  SelectLanguagesModal,
} from '@components/modals';
import { ToggleSwitch } from '@components/ToggleSwitch';
import { QUERY_KEY } from '@constants/queryKey';
import { User, UserRole } from '@core/models';
import { useErrorMessage } from '@hooks/useErrorMessage';
import useMakeErrorMessageFromResponse from '@hooks/useMakeErrorMessageFromResponse';
import { useNavigationRoute } from '@hooks/useNavigationRoute';
import { usePdfGuide } from '@hooks/usePdfGuide';
import { useUser } from '@hooks/useUser';
import { useUserActiveLanguageToggle } from '@hooks/useUserActiveLanguageToggle';
import { useUserMenuLanguage } from '@hooks/useUserMenuLanguage';
import { Typography } from '@material-ui/core';
import {
  ArrowBack as ArrowBackIcon,
  HomeRounded as HomeIcon,
  Language as LanguageIcon,
  Person as ProfileIcon,
} from '@material-ui/icons';
import Bowser from 'bowser';
import React, { FC, MouseEvent, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery } from 'react-query';
import { matchRoutes } from 'react-router-config';
import { useHistory } from 'react-router-dom';
import { privateRoutes } from 'src/routes';
import {
  AppTitle,
  AppTitleIconButton,
  LeftSection,
  MiddleSection,
  ProfileMenu,
  ProfileMenuItem,
  ProfileMenuItemSelectLanguages,
  RightSection,
  StyledAppBar,
  StyledToolbar,
  StyledToggleButton,
  LanguageWidgetIcon,
  SignLanguageIcon,
  UserNameText,
  UserProfileIconButton,
  WavyAppBarBackground,
  StyledAnchor,
} from './AppBar.styled';

const { name: browserName } = Bowser.parse(window.navigator.userAgent).browser;
const isTouchDevice = window.navigator.maxTouchPoints > 0;
const isOnIos = browserName === 'Safari' && isTouchDevice;

const AppBar: FC = () => {
  const USER_MENU_ID = 'user-menu';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { push, location } = useHistory();
  const { formatMessage } = useIntl();

  const { user, setUser } = useUser();
  const { backToPath } = useNavigationRoute();
  const { defaultMenuLanguage } = useUserMenuLanguage();
  const { setErrorMessage } = useErrorMessage();

  const { makeErrorMessage } = useMakeErrorMessageFromResponse();

  const [matchedRoute] = matchRoutes(privateRoutes, location.pathname);
  const isOnSubcategoryPage = /^\/language(-parents)?\/\S+\/subcategory$/.test(location.pathname);

  const [selectLanguagesModalOpen, setSelectLanguagesModalOpen] = useState(
    !(user?.firstLanguage && user?.secondLanguage)
  );
  const [openMenuLanguageModal, setOpenMenuLanguageModal] = useState(false);
  const [openLogoutConfirmationModal, setOpenLogoutConfirmationModal] = useState(false);
  const [openResetContentConfirmationModal, setOpenResetContentConfirmationModal] = useState(false);

  const { data: languages = [], isLoading: isLoadingLanguages } = useQuery(
    [QUERY_KEY.LANGUAGES, user?.menuLanguage || defaultMenuLanguage],
    (key, localeCode: string) => fetchLanguages({ localeCode }),
    {
      onError: (error) => setErrorMessage(makeErrorMessage(error)),
    }
  );

  const { firstLanguageData, secondLanguageData, handleToggleLangauge } = useUserActiveLanguageToggle(languages);

  const { data: appConfigs } = useQuery(['PDF_GUIDE'], fetchAppConfigs);
  const userGuideUrl = appConfigs?.userGuideUrl || '';
  const { openPdfInNewWindow, loadingPdf } = usePdfGuide(userGuideUrl);

  const [userSettingMutation] = useMutation(userSetting, {
    onMutate: ({ payload }) => {
      if (!user) {
        setErrorMessage(formatMessage({ id: 'error_message.user_not_found' }));
        return;
      }
      setUser({ ...user, ...payload });
      return user;
    },
    onError: (error, variable, onMutateValue?: User) => {
      setErrorMessage(formatMessage({ id: 'error_message.error_switching_language' }));
      if (!user) {
        setErrorMessage(formatMessage({ id: 'error_message.user_not_found' }));
        return;
      } else if (onMutateValue) {
        setUser(onMutateValue);
      }
    },
  });

  const handleClickUserMenuButton = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseSelectLanguagesModal = useCallback(() => {
    setSelectLanguagesModalOpen(false);
  }, []);

  const handleClickSelectLanguagesMenu = () => {
    handleCloseUserMenu();

    setSelectLanguagesModalOpen(true);
  };

  const handleClickMenuLanguageMenu = () => {
    handleCloseUserMenu();

    setOpenMenuLanguageModal(true);
  };

  const handleClickHelpMenu = () => {
    handleCloseUserMenu();

    openPdfInNewWindow();
  };

  const handleClickResetContentMenu = () => {
    handleCloseUserMenu();

    setOpenResetContentConfirmationModal(true);
  };

  const handleClickLogoutMenu = () => {
    handleCloseUserMenu();

    setOpenLogoutConfirmationModal(true);
  };

  const handleClickAppTitleIconButton = () => {
    const path = isOnSubcategoryPage ? backToPath : matchedRoute.route.backTo;

    push(path);
  };

  const handleClickSignLanguage = () => {
    userSettingMutation({
      payload: {
        isSignLanguage: !(user?.isSignLanguage ?? true),
      },
    });
  };

  const handleClickWidget = () => {
    userSettingMutation({
      payload: {
        isWidgetSymbol: !(user?.isWidgetSymbol ?? true),
      },
    });
  };

  return (
    <StyledAppBar position="fixed" color="transparent">
      <StyledToolbar>
        <LeftSection>
          <AppTitleIconButton onClick={handleClickAppTitleIconButton}>
            {matchedRoute.route.backTo === '/modes' && !isOnSubcategoryPage ? (
              <HomeIcon color="primary" />
            ) : (
              <ArrowBackIcon color="primary" fontSize="large" />
            )}
          </AppTitleIconButton>

          <AppTitle display="inline">
            <FormattedMessage id={`modes.title.${matchedRoute.route.title}`} />
          </AppTitle>
        </LeftSection>

        <MiddleSection>
          <ToggleSwitch
            color="primary"
            width="19.75rem"
            value={user?.isFirstLanguage ?? true ? firstLanguageData?.langCode : secondLanguageData?.langCode}
            onChange={handleToggleLangauge}
            options={{
              left: { value: firstLanguageData?.langCode || '', label: firstLanguageData?.title || '' },
              right: { value: secondLanguageData?.langCode || '', label: secondLanguageData?.title || '' },
            }}
          />

          <StyledToggleButton value="sign-language" selected={user?.isSignLanguage} onChange={handleClickSignLanguage}>
            <SignLanguageIcon />
          </StyledToggleButton>

          <StyledToggleButton value="language-widget" selected={user?.isWidgetSymbol} onChange={handleClickWidget}>
            <LanguageWidgetIcon />
          </StyledToggleButton>
        </MiddleSection>

        <RightSection>
          <UserNameText display="inline">{user?.name}</UserNameText>

          <UserProfileIconButton aria-controls={USER_MENU_ID} aria-haspopup="true" onClick={handleClickUserMenuButton}>
            <ProfileIcon />
          </UserProfileIconButton>
        </RightSection>
      </StyledToolbar>

      <ProfileMenu
        id={USER_MENU_ID}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseUserMenu}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ProfileMenuItemSelectLanguages
          onClick={handleClickSelectLanguagesMenu}
          language={user?.isFirstLanguage ?? true ? 'first' : 'second'}
          divider
        >
          <LanguageIcon />
          <Typography>{firstLanguageData?.title || '-'}</Typography>
          <Typography>{secondLanguageData?.title || '-'}</Typography>
        </ProfileMenuItemSelectLanguages>

        <ProfileMenuItem onClick={handleClickMenuLanguageMenu} divider>
          <FormattedMessage id="app_bar.profile_menu.menu_language" />
        </ProfileMenuItem>

        <ProfileMenuItem onClick={handleClickResetContentMenu} divider>
          <FormattedMessage id="app_bar.profile_menu.reset_content" />
        </ProfileMenuItem>

        {user?.role === UserRole.Teacher &&
          (isOnIos ? (
            <StyledAnchor href={userGuideUrl} target="_blank" rel="noopener noreferrer" onClick={handleCloseUserMenu}>
              <ProfileMenuItem divider>
                <FormattedMessage id="app_bar.profile_menu.help" />
              </ProfileMenuItem>
            </StyledAnchor>
          ) : (
            <ProfileMenuItem onClick={handleClickHelpMenu} divider>
              <FormattedMessage id="app_bar.profile_menu.help" />
            </ProfileMenuItem>
          ))}

        <ProfileMenuItem onClick={handleClickLogoutMenu}>
          <FormattedMessage id="app_bar.profile_menu.sign_out" />
        </ProfileMenuItem>
      </ProfileMenu>

      <SelectLanguagesModal
        languages={languages}
        isLoadingLanguages={isLoadingLanguages}
        open={selectLanguagesModalOpen}
        onClose={handleCloseSelectLanguagesModal}
      />

      <MenuLanguageModal open={openMenuLanguageModal} onClose={() => setOpenMenuLanguageModal(false)} />

      <ResetContentConfirmationModal
        open={openResetContentConfirmationModal}
        onClose={() => setOpenResetContentConfirmationModal(false)}
      />

      <LogoutConfirmationModal
        open={openLogoutConfirmationModal}
        onClose={() => setOpenLogoutConfirmationModal(false)}
      />

      <WavyAppBarBackground />

      {loadingPdf && <FullPageLoadingSpinner />}
    </StyledAppBar>
  );
};

export { AppBar };
