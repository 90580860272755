import { Button } from '@material-ui/core';
import { styled } from '@styles/styled';
import { getWidth } from '@utils/common';
import { ButtonRoundedProps } from './ButtonRounded';

export const StyledButtonRounded = styled(Button)<ButtonRoundedProps & { accent: string }>`
  width: ${({ width }) => getWidth(width)};
  height: 2.875rem;
  border-radius: 23px;
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 0px;
  box-shadow: none;
  padding: 0;

  &:hover {
    box-shadow: none;
  }

  &.MuiButton-text {
    background-color: transparent;

    &:hover {
      background-color: ${({ theme }) => theme.palette.action.hover};
    }
  }


  ${({ accent, theme }) => {
    switch (accent) {
      case 'primary':
        return `
          background-color: ${theme.palette.primary.main};
          color: ${theme.palette.primary.contrastText};

          &:hover {
            background-color: ${theme.palette.primary.dark};
          }

          &.Mui-disabled {
            background-color: ${theme.palette.primary.light};
            color: ${theme.palette.secondary.light};
          }
        `;
      case 'secondary':
        return `
          background-color: ${theme.palette.secondary.light};
          color: ${theme.palette.primary.main};

          &:hover {
            background-color: ${theme.palette.secondary.main};
          }

          &.Mui-disabled {
            background-color: #dad8e4;
            color: #b3a9e8;
          }
        `;
      case 'danger':
        return `
          background-color: ${theme.palette.error.main};
          color: ${theme.palette.primary.contrastText};

          &:hover {
            background-color: ${theme.palette.error.dark};
          }

          &.Mui-disabled {
            background-color: ${theme.palette.error.light};
            color: ${theme.palette.primary.contrastText};
          }
        `;
      case 'default':
        return `
          background-color: #f8f3f3;
          color: #202527;

          &:hover {
            background-color: #ebebeb;
          }
        `;
    }
  }}

  /* ============================================ */
  /* TODO: DECRECATED - use "colorAccent" instead */
  ${({ color, theme }) => {
    switch (color) {
      case 'secondary':
        return `
          background-color: ${theme.palette.secondary.light};
          color: ${theme.palette.primary.main};

          &:hover {
            background-color: ${theme.palette.secondary.main};
          }
        `;
      case 'default':
        return `
          background-color: #f8f3f3;
          color: #202527;

          &:hover {
            background-color: #ebebeb;
          }
        `;
    }
  }}/* ============================================ */
`;
