import { ReactComponent as LanguageWidgetSvg } from '@assets/svg/language-widget-icon.svg';
import { ReactComponent as SignLanguageSvg } from '@assets/svg/sign-language-icon.svg';
import { ReactComponent as WavyAppBarSVG } from '@assets/svg/wavy-appbar.svg';
import { AppBar, Box, Toolbar, Menu, MenuItem, Typography, IconButton } from '@material-ui/core';
import { ToggleButton } from '@material-ui/lab';
import { styled } from '@styles/styled';

const menuItemStyles = `
  padding: 22px 25px;
`;

const menuItemTextStyles = `
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
`;

export const StyledAppBar = styled(AppBar)`
  height: ${({ theme }) => theme.custom.appBar.height};
  box-shadow: none;
  color: white;
`;

export const StyledToolbar = styled(Toolbar)`
  height: 5.125rem;
  min-height: unset;
  padding: 0 1.5rem;
  display: flex;
  align-items: flex-end;
  background-color: ${({ theme }) => theme.palette.primary.main};
`;

export const LeftSection = styled(Box)`
  flex: 1 1 0;
  display: flex;
  align-items: center;
`;

export const MiddleSection = styled(Box)`
  flex: 1.8 1 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > button:first-of-type {
    margin: 0 0.75em;
  }
`;

export const RightSection = styled(Box)`
  flex: 1 1 0;
  text-align: right;
`;

export const AppTitleIconButton = styled(IconButton)`
  background-color: white;
  width: 2.75rem;
  height: 2.75rem;
  padding: 0px;

  &:hover {
    background-color: ${({ theme }) => theme.palette.grey[50]};
  }
`;

export const UserProfileIconButton = styled(IconButton)`
  border: 3px solid white;
  box-shadow: inset 0px 0px 0px 4px #6751e7;
  width: 2.75rem;
  height: 2.75rem;
  margin-left: 1.75rem;
  padding: 0px;

  & svg.MuiSvgIcon-root {
    fill: white;
    font-size: 2rem;
  }
`;

export const AppTitle = styled(Typography)`
  font-size: 2.25rem;
  font-family: 'Cocogoose';
  letter-spacing: -0.9px;
  margin-left: 1.125rem;
`;

export const StyledToggleButton = styled(ToggleButton)`
  width: 3rem;
  height: 3rem;
  padding: 0;
  border: none;
  border-radius: 50%;
  box-shadow: 3px 1px 4px 0px #4c3ca7, -3px -1px 4px 0px #9a90d6;
  background-color: ${({ theme }) => theme.palette.primary.main};

  & svg {
    fill: white;
  }

  &:hover {
    background-color: #5f48e0;
  }

  &&.Mui-selected {
    box-shadow: inset 1px 1px 3px 0px #876fb5, inset -2px -1px 4px 0px #9a90d687;
    background-color: #d9d3ff;

    &[value='sign-language'] {
      & > span > svg {
        height: 1.7rem;
        fill: #241483;
      }
    }

    &[value='language-widget'] {
      & > span > svg {
        height: 2.2rem;
        fill: #241483;

        & rect#rectangle-top {
          stroke: #241483;
        }

        & g#rectangle-bottom {
          fill: #d9d3ff;

          & > rect {
            stroke: #241483;
          }
        }

        & g#stick-man-walking {
          fill: #241483;
        }

        & g#exchange {
          fill: #241483;
          stroke: #d9d3ff;
        }
      }
    }
  }
`;

export const SignLanguageIcon = styled(SignLanguageSvg)`
  height: 1.875rem;
`;

export const LanguageWidgetIcon = styled(LanguageWidgetSvg)`
  height: 2.4rem;
`;

export const UserNameText = styled(Typography)`
  font-family: 'Raleway';
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: -0.45px;
`;

export const ProfileMenu = styled(Menu)`
  & ul.MuiList-padding {
    padding: 0;
  }
`;

export const ProfileMenuItem = styled(MenuItem)`
  ${menuItemStyles}
  ${menuItemTextStyles}
`;

export const ProfileMenuItemSelectLanguages = styled(MenuItem)`
  ${menuItemStyles}

  display: grid;
  grid-template-columns: 24px 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 7px;
  column-gap: 10px;
  grid-template-areas:
    'icon-top first-language'
    'icon-bottom second-language';

  & > svg.MuiSvgIcon-root {
    grid-area: ${({ language }: { language: string }) => (language === 'first' ? 'icon-top' : 'icon-bottom')};
  }

  & > p.MuiTypography-body1 {
    ${menuItemTextStyles}

    &:first-of-type {
      grid-area: first-language;
    }
    &:last-of-type {
      grid-area: second-language;
    }
  }
`;

export const WavyAppBarBackground = styled(WavyAppBarSVG)`
  top: 4.6875rem;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: -1;
`;

export const StyledAnchor = styled.a`
  color: inherit;
  text-decoration: none;
`;
