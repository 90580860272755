import { List, ListItem, ListItemText } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { styled } from '@styles/styled';

export const StyledLanguageList = styled(List)`
  overflow-y: auto;
  padding: 1rem 2rem;
`;

export const StyledLanguageListItem = styled(ListItem)`
  margin: 0.5rem 0;
  height: 2.5rem;
  border-radius: 24px;

  &.MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    ${({ selected, theme }) =>
      selected &&
      `
    background-color: ${theme.palette.secondary.light};
    color: ${theme.palette.primary.main};
    `}

    &:focus {
      background-color: #d6d2f5;
    }
  }
`;

export const StyledLanguageListItemText = styled(ListItemText)`
  & > .MuiTypography-body1 {
    color: inherit;
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0px;
  }
`;

export const LanguageListItemSkeleton = styled(Skeleton)`
  margin: 1.5rem 0.75rem;
`;
