import { styled } from '@styles/styled';

export const StyleButton = styled.div<{ width: number | string | undefined; height: number | string | undefined }>`
  background: rgb(103, 81, 231);
  height: ${({ height }) => height || '4.3125rem'};
  width: ${({ width }) => width || '4.3125rem'};
  border-radius: 25px;
  cursor: pointer;
`;

export const IconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
