import CocogooseLightWoff2 from '@assets/fonts/Cocogoose-Light.woff2';
import CocogooseRegularWoff2 from '@assets/fonts/Cocogoose-Regular.woff2';
import RalewayBoldWoff2 from '@assets/fonts/Raleway-Bold.woff2';
import RalewayRegularWoff2 from '@assets/fonts/Raleway-Regular.woff2';
import SFProDisplayBoldWoff2 from '@assets/fonts/SFProDisplay-Bold.woff2';
import SFProDisplayRegularWoff2 from '@assets/fonts/SFProDisplay-Regular.woff2';
import * as CSS from 'csstype';

export const cocogooseLight: CSS.AtRule.FontFace = {
  fontFamily: 'Cocogoose',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `url(${CocogooseLightWoff2}) format('woff2')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const cocogooseRegular: CSS.AtRule.FontFace = {
  fontFamily: 'Cocogoose',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${CocogooseRegularWoff2}) format('woff2')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ralewayRegular: CSS.AtRule.FontFace = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${RalewayRegularWoff2}) format('woff2')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const ralewayBold: CSS.AtRule.FontFace = {
  fontFamily: 'Raleway',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${RalewayBoldWoff2}) format('woff2')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sfProDisplayRegular: CSS.AtRule.FontFace = {
  fontFamily: 'SFProDisplay',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${SFProDisplayRegularWoff2}) format('woff2')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export const sfProDisplayBold: CSS.AtRule.FontFace = {
  fontFamily: 'SFProDisplay',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${SFProDisplayBoldWoff2}) format('woff2')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};
