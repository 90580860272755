export enum UserRole {
  Teacher = 'teacher',
  Student = 'student',
}

export type User = {
  id: string;
  name: string;
  userName: string;
  role: UserRole;
  isFeide: boolean;
  firstLanguage?: string;
  secondLanguage?: string;
  isFirstLanguage?: boolean;
  menuLanguage?: string;
  isSignLanguage?: boolean;
  isWidgetSymbol?: boolean;
  isDoubleSize?: boolean;
};
