import { CreateCustomTokenPayload, createCustomToken } from '@api/authentication';
import { FullPageLoadingSpinner } from '@components/FullPageLoadingSpinner';
import { ErrorMessageSnackbar } from '@components/Snackbar';
import { LocalStorageKey } from '@constants/localStorageKey';
import { auth } from '@core/firebase';
import { useLoginWithCustomTokenFromRedirect } from '@hooks/useLoginWithCustomTokenFromRedirect';
import useViewportHeight from '@hooks/useViewportHeight';
import React, { FC, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RouteComponentProps } from 'react-router-dom';
import { KidLoginForm, AdultLoginForm, FeideLoginButton, LoginModeButton } from './components';
import { LoginPageWrapper, LoginCardWrapper, FeideLoginText } from './Login.styled';

enum LoginMode {
  Kid = 'Kid',
  Adult = 'Adult',
}

const Login: FC<RouteComponentProps> = ({ history, location }) => {
  const { formatMessage } = useIntl();
  const viewportHeight = useViewportHeight();

  const { isLoggingIn, isInvalidCustomToken, setIsInvalidCustomToken } = useLoginWithCustomTokenFromRedirect();
  const [isUnauthorized, setIsUnauthorized] = useState(false);

  useEffect(() => {
    setIsUnauthorized(false);

    if (location.search === '?errorCode=401') {
      setIsUnauthorized(true);
    }
  }, [history, location.search, setIsUnauthorized]);

  const [loginMode, setLoginMode] = useState(localStorage.getItem(LocalStorageKey.LOGIN_MODE) || LoginMode.Kid);

  const [isLogginInWithFeide, setIsLogginInWithFeide] = useState(false);
  const [isLogginInWithUsername, setIsLogginInWithUsername] = useState(false);

  const handleSetLoginMode = (mode: LoginMode) => {
    setLoginMode(mode);

    localStorage.setItem(LocalStorageKey.LOGIN_MODE, mode);
  };

  const handleLoginWithUsername = async (payload: CreateCustomTokenPayload) => {
    setIsLogginInWithUsername(true);

    try {
      const data = await createCustomToken(payload);

      await auth.signInWithCustomToken(data.customToken);

      setIsLogginInWithUsername(false);

      history.push('/');

      return data;
    } catch (error) {
      setIsLogginInWithUsername(false);

      throw error;
    }
  };

  const handleLoginWithFeide = () => {
    const url = process.env.FEIDE_LOGIN_URL;

    if (!url) return;

    setIsLogginInWithFeide(true);

    window.location.href = url;
  };

  const handleCloseErrorSnackbar = () => {
    setIsInvalidCustomToken(false);
    setIsUnauthorized(false);

    history.replace('/login');
  };

  return (
    <LoginPageWrapper viewportHeight={viewportHeight}>
      <LoginCardWrapper>
        <LoginModeButton selected={loginMode === LoginMode.Kid} onClick={() => handleSetLoginMode(LoginMode.Kid)}>
          <FormattedMessage id="login.kids" />
        </LoginModeButton>
        <LoginModeButton selected={loginMode === LoginMode.Adult} onClick={() => handleSetLoginMode(LoginMode.Adult)}>
          <FormattedMessage id="login.adults" />
        </LoginModeButton>

        {loginMode === LoginMode.Kid ? <KidLoginForm onSubmit={handleLoginWithUsername} /> : <AdultLoginForm />}

        <FeideLoginText align="center">
          <FormattedMessage id="login.login_with_feide" />
        </FeideLoginText>
        <FeideLoginButton onClick={handleLoginWithFeide} />
      </LoginCardWrapper>

      <FullPageLoadingSpinner isLoading={isLoggingIn || isLogginInWithFeide || isLogginInWithUsername} />

      <ErrorMessageSnackbar
        message={formatMessage({ id: 'error_message.error_logging_in' })}
        open={isInvalidCustomToken || isUnauthorized}
        onClose={handleCloseErrorSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </LoginPageWrapper>
  );
};

export { Login };
