import { MENU_LANGUAGE } from '@constants/menuLanguage';
import { useUser } from '@hooks/useUser';
import React, { FC, createContext } from 'react';
import { IntlProvider } from 'react-intl';
import translations from '../translations';

type Messages = {
  [locale in MENU_LANGUAGE]: { [translationKey: string]: string };
};

type UserMenuLanguageContextProps = {
  defaultMenuLanguage: MENU_LANGUAGE;
};

const fallbackLocale = MENU_LANGUAGE.English;

export const UserMenuLanguageContext = createContext<UserMenuLanguageContextProps>({
  defaultMenuLanguage: fallbackLocale,
});

export const UserMenuLanguageContextProvider: FC = ({ children }) => {
  const { user } = useUser();

  const appLocale = (user?.menuLanguage || process.env.DEFAULT_MENU_LANGUAGE) as MENU_LANGUAGE;
  const messages = (translations as Messages)[appLocale];

  return (
    <UserMenuLanguageContext.Provider value={{ defaultMenuLanguage: appLocale }}>
      <IntlProvider messages={messages} locale={appLocale} defaultLocale={fallbackLocale}>
        {children}
      </IntlProvider>
    </UserMenuLanguageContext.Provider>
  );
};
