import { Snackbar as MuiSnackbar } from '@material-ui/core';
import { styled } from '@styles/styled';

export const StyleSnackbar = styled(MuiSnackbar)`
  &.MuiSnackbar-anchorOriginTopCenter {
    top: 3.75rem;

    & > .MuiSnackbarContent-root {
      max-width: 25rem;
      border-radius: 1.875rem;
      padding: 1rem 1.5rem;

      & > .MuiSnackbarContent-message {
        width: 100%;
        font-family: 'Raleway';
        font-size: 1.25rem;
        font-weight: bold;
        text-align: center;
      }

      & > .MuiSnackbarContent-action {
        width: 100%;
        justify-content: center;
        margin: 1rem 0 0 0;
        padding: 0;

        & > .MuiButton-root {
          padding: 0 1.25rem;
        }
      }
    }
  }
`;
