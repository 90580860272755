import { axiosInstance } from '@core/axiosInstance';
import { AxiosResponse } from 'axios';

export type CreateCustomTokenPayload = {
  userName: string;
  classCode: string;
};

export type CreateCustomTokenResponseData = {
  customToken: string;
};

export const createCustomToken = (payload: CreateCustomTokenPayload) =>
  axiosInstance.post('/User/LogIn', payload).then(({ data }: AxiosResponse<CreateCustomTokenResponseData>) => data);

export const logout = () => axiosInstance.post('/User/LogOut');
