export enum QUERY_KEY {
  LANGUAGES = 'LANGUAGES',
  LANGUAGE_CARDS = 'LANGUAGE_CARDS',
  LANGUAGE_CARD = 'LANGUAGE_CARD',
  CATEGORIES = 'CATEGORIES',
  SUBCATEGORIES = 'SUBCATEGORIES',
  CLASSES = 'CLASSES',
  CLASS = 'CLASS',
  GROUPS = 'GROUPS',
  GROUP = 'GROUP',
  GROUP_STUDENTS = 'GROUP_STUDENTS',
  SHARE_WITH_LIST = 'SHARE_WITH_LIST',
  PLACEMENT_LIST = 'PLACEMENT_LIST',
  LANGUAGE_CARD_LIBRARY = 'LANGUAGE_CARD_LIBRARY',
  LANGUAGE_CARD_LIBRARY_MINDMAP = 'LANGUAGE_CARD_LIBRARY_MINDMAP',
  LANGUAGE_CARD_LIBRARY_SEARCH = 'LANGUAGE_CARD_LIBRARY_SEARCH',
  LANGUAGE_CARD_LIBRARY_SEARCH_MINDMAP = 'LANGUAGE_CARD_LIBRARY_SEARCH_MINDMAP',
  KIDS_MODE = 'KIDS_MODE',
  PARENTS_MODE = 'PARENTS_MODE',
  MINDMAP_PROJECTS = 'MINDMAP_PROJECTS',
  MINDMAP_DETAILS = 'MINDMAP_DETAILS',
  SANDBOX_LIST = 'SANDBOX_LIST',
  SANDBOX_DETAIL = 'SANDBOX_DETAIL',
}
