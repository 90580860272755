import { ButtonRounded } from '@components/buttons';
import { TextField } from '@components/TextField';
import { Formik } from 'formik';
import React, { FC, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import { postDataWithForm } from './adultFormHelpers';
import { StyledFormikForm } from './AdultLoginForm.styled';

type AdultLoginFormValues = {
  promoCode: string;
};

const AdultLoginForm: FC = () => {
  const { formatMessage } = useIntl();

  const validationSchema = Yup.object().shape({
    promoCode: Yup.string().required(formatMessage({ id: 'login.form.promo_code.validation_message' })),
  });

  const formElementRef = useRef<HTMLFormElement>(null);
  const inputElementRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Formik
        initialValues={{
          promoCode: '',
        }}
        onSubmit={(values: AdultLoginFormValues) => {
          postDataWithForm(formElementRef.current, inputElementRef.current, values.promoCode);
        }}
        validationSchema={validationSchema}
      >
        <StyledFormikForm>
          <TextField
            name="promoCode"
            label={formatMessage({ id: 'login.form.promo_code.label' })}
            placeholder={formatMessage({ id: 'login.form.promo_code.placeholder' })}
          />

          <ButtonRounded type="submit" colorAccent="primary" fullWidth>
            <FormattedMessage id="login.form.button.sign_in_with_promo_code" />
          </ButtonRounded>
        </StyledFormikForm>
      </Formik>

      <form ref={formElementRef} method="POST" action={process.env.PROMOCODE_LOGIN_URL}>
        <input name="msg" type="hidden" ref={inputElementRef} />
      </form>
    </>
  );
};

export { AdultLoginForm };
