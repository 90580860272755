import { css } from '@emotion/core';
import { IconButton } from '@material-ui/core';
import { styled } from '@styles/styled';
import { ElevatedIconButtonColorAccent } from './ElevatedIconButton';

export const StyledIconButton = styled(IconButton)<{ accent: ElevatedIconButtonColorAccent }>(({ theme, accent }) => {
  const resolveColorPropertiesByAccent = () => {
    switch (accent) {
      case 'default':
        return {
          backgroundColor: theme.palette.grey[300],
          boxShadowColor: theme.palette.grey[400],
          svgFill: 'currentColor',
        };
      case 'secondary':
        return {
          backgroundColor: theme.palette.secondary.main,
          boxShadowColor: '#9783ff',
          svgFill: '#241483',
        };
      case 'danger':
        return {
          backgroundColor: '#faa5a7',
          boxShadowColor: '#e76a6a',
          svgFill: '#d63f3f',
        };
    }
  };

  const { backgroundColor, boxShadowColor, svgFill } = resolveColorPropertiesByAccent();

  return css`
    width: 2.75rem;
    height: 2.75rem;
    background-color: ${backgroundColor};
    box-shadow: 1px 2px 0px 0px ${boxShadowColor};

    &:hover {
      background-color: ${backgroundColor};
    }

    &:active {
      box-shadow: none;
      transform: translate(1px, 2px);
    }

    &.MuiIconButton-root.Mui-disabled {
      opacity: 0.5;
      color: unset;
      background-color: ${backgroundColor};
    }

    & svg {
      font-size: 2rem;
      fill: ${svgFill};
    }
  `;
});
