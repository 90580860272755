import { auth } from '@core/firebase';
import axios, { AxiosRequestConfig } from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.API_ENDPOINT_BASE_URL,
  timeout: 30000,
});

axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const user = auth.currentUser;

  if (user) {
    const accessToken = await user.getIdToken();

    config.headers.authorization = `Bearer ${accessToken}`;
  }

  return config;
});
